.easy-breadcrumb {
  background-color: $grey-4;
  color: $grey-5;
  margin-bottom: 20px;
  padding: 8px 15px;
  font-size: 14px;
 
  //margin-left: 15px;
  //margin-right: 15px;

  .easy-breadcrumb_segment-separator {
    margin: 0 4px;
  }

  a {
    color: $grey-5;
    font-weight: 500;

    &:hover {
      color: $grey-5;
      font-weight: bold;
    }
  }
}