.page-specials {
  .block-view-special {
    h2.block-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 118.69%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #3f3f3f;
    }
  }

  .view-special {
    .views-row {
      width: 25%;

      @include respond-below(lg) {
        width: 50%;
      }

      @include respond-below(sm) {
        width: 100%;
      }

      .node-special {
        text-align: center;
        width: 100%;

        .field-name-field-file {
          width: 100%;
        }

        .file-image a {
          margin: 0 auto;
          display: inline-block;

          img {
            width: auto;
            height: auto;
            max-width: 100%;
          }
        }

        > .links > .node-readmore {
          margin-top: 2rem;
        }

        @include respond-below(sm) {
          margin: 0 auto;
        }
      }
      a.btn.btn-secondary {
        background-color: #111111;
        width: 100%;
        border-radius: 0;
        font-size: 20px;
        line-height: 1.5;
        margin: 10px 0;
      }
    }
  }
  .view-product-solr-search {
    .view-content {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      column-gap: 4rem;
      row-gap: 4rem;

      @include respond-below(xxl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 4rem;
        row-gap: 4rem;
      }

      @include respond-below(md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include respond-below(sm) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      .views-row {
        width: 100%;
      }
    }
  }
}

.page-node.node-type-special .node-special {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3f3f3f;
  align-items: center;

  > a {
    @include btn($orange-3);
    padding: 0.9rem 3.5rem;
    line-height: 1.2;
    margin: 20px 0;
    border-radius: 3px;
    &::before {
      content: "";
      background-image: url(../../images/icon-download.png);
      background-repeat: no-repeat;
      width: 30px;
      height: 23px;
      display: inline-block;
      margin-right: 10px;
    }
  }

  > p {
    margin: 20px 0 30px;
  }

  .special-call-us {
    display: flex;
    align-items: center;
    &::before {
      content: "";
      background-image: url(../../images/icon-phone.png);
      background-repeat: no-repeat;
      width: 18px;
      height: 23px;
      display: inline-block;
      margin-right: 5px;
    }
    a {
      font-size: 20px;
      font-weight: 700;
      color: #3f3f3f;
      text-decoration: none;
      margin-left: 3px;
    }
  }

  .file-image {
    margin-bottom: 40px;
  }
}
