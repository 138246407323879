.page-user-wish-list-,
.page-user-wish-list {
  .region-content > section {
    display: flex;
    flex-direction: column;

    table {
      &#sort-table {
        thead {
          th {
            &:nth-child(4),
            &:last-child {
              width: 150px;
            }
          }
        }
      }

      @include respond-below(sm) {
        min-width: 800px;
      }

      tbody,
      thead {
        tr {
          td,
          th {
            //overwrite filter icon size and spacing
            span.icon {
              padding-left: 6px;
              padding-right: 6px;
              &::before {
                font-size: 10px;
              }
            }
          }

          td {
            padding-top: 15px;
            padding-bottom: 15px;

            &:first-child {
              a {
                color: $black;
                font-weight: 500;
                margin-bottom: 0;
                @include fa-icon('\f00c');
                &::before {
                  font-weight: normal;
                }
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }

    > .table-responsive {
      margin-top: 20px;
      width: 100%;
    }

    > a {
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
      padding: 6px 12px;
      border-radius: 4px;
      background-color: #f59129;
      color: white;
      text-decoration: none;
      text-align: right;
      align-self: flex-end;
      border: 1px solid #f59129;

      &:hover {
        background-color: #e1780a;
      }
    }
  }

  .node-product {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .field-name-uc-product-image {
    img {
      height: 50px;
      object-fit: contain;
      width: 50px;
    }

    .field-item:not(:first-child) {
      display: none;
    }
  }

  .field-name-title {
    margin: 0 15px;
  }

  #show-list-items-table {
    td {
      vertical-align: middle;
    }

    h2,
    a,
    span {
      color: $black;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.42857;
      margin: 0;
    }

    tbody td:not(:nth-child(2)) {
      text-align: center;
    }

    thead {
      tr {
        th {
          &:last-child {
            width: 300px;
          }
        }
      }
    }
  }

  // New List Form
  #uc-wishlist-create-list-form {
    margin-top: 30px;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    @include wrapper(relative, 100%);

    > div {
      @include wrapper(relative, 100%);
      @include respond-above(sm) {
        flex-direction: row;
      }

      display: flex;
      flex-direction: column;

      .form-group {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        height: 100%;
        @include respond-above(sm) {
          flex-direction: row;
        }
      }

      .form-control {
        margin-bottom: 0;
      }

      label {
        //width: 100%;
        min-width: 100px;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
      }

      button {
        height: 35px;
        @include respond-above(sm) {
          margin-left: 4px;
        }
      }
    }
  } // Form
}
