//custom mixin

@mixin animated($duration: 300ms) {
  transition: all 300ms ease;
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  -o-transition: all $duration ease;
}

$animationDurationTime: 1s;
$animationDelayTime: 0s;
$animationFillMode: both;
//$animationLessLocation: './source';

@mixin cssanimated($animationDurationTime: 1s, $animationDelayTime: 0s, $animationFillMode: both) {
  -webkit-animation-duration: $animationDurationTime;
  -moz-animation-duration: $animationDurationTime;
  -o-animation-duration: $animationDurationTime;
  animation-duration: $animationDurationTime;
  -webkit-animation-delay: $animationDelayTime;
  -moz-animation-delay: $animationDelayTime;
  -o-animation-delay: $animationDelayTime;
  animation-delay: $animationDelayTime;
  -webkit-animation-fill-mode: $animationFillMode;
  -moz-animation-fill-mode: $animationFillMode;
  -o-animation-fill-mode: $animationFillMode;
  animation-fill-mode: $animationFillMode;
  margin: 1rem;
}

@mixin animation($name, $duration: 300ms, $delay: 0, $ease: ease) {
  -webkit-animation: $name $duration $delay $ease;
  -moz-animation: $name $duration $delay $ease;
  -ms-animation: $name $duration $delay $ease;
}

@mixin myanimated($duration: 300ms) {
  transition: all 300ms ease;
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  -o-transition: all $duration ease;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
}
@mixin transform($string) {
  -webkit-transform: $string;
  -moz-transform: $string;
  -ms-transform: $string;
  -o-transform: $string;
}
@mixin scale($factor) {
  -webkit-transform: scale($factor);
  -moz-transform: scale($factor);
  -ms-transform: scale($factor);
  -o-transform: scale($factor);
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -o-transform: rotate($deg);
}
@mixin skew($deg, $deg2) {
  -webkit-transform: skew($deg, $deg2);
  -moz-transform: skew($deg, $deg2);
  -ms-transform: skew($deg, $deg2);
  -o-transform: skew($deg, $deg2);
}
@mixin translate($x, $y: 0) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
}
@mixin translate3d($x, $y: 0, $z: 0) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -ms-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
}
@mixin perspective($value: 1000) {
  -webkit-perspective: $value;
  -moz-perspective: $value;
  -ms-perspective: $value;
  perspective: $value;
}
@mixin transform-origin($x: center, $y: center) {
  -webkit-transform-origin: $x $y;
  -moz-transform-origin: $x $y;
  -ms-transform-origin: $x $y;
  -o-transform-origin: $x $y;
}

/* Animation */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 100%;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
