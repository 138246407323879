//Mixin wrapper
@mixin wrapper($position: relative, $width: auto, $height: auto) {
  position: $position;
  width: $width;
  height: $height;
}

@mixin global-grid-padding() {
  @include respond-above(md) {
    //992px Up tablet
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
}

@mixin makeContainerFluid($breakpoint: $screen-xl) {
  .container {
    // Full stretch after 1330px :  Fluid 100%
    @media all and (max-width: $screen-xl) {
      width: 100%;
    }
  }
}

@mixin fluidContainerMaxWidth($breakpoint: $screen-xl) {
  // Fixed to $screen-xl then full stretch 100% after 1330px
  .container-fluid {
    @media all and (min-width: $screen-xl) {
      width: $screen-xl;
    }
  }
}

@mixin flex-container-wrapper(
  $flexdirection: row,
  $flexwrap: wrap,
  $justifycontent: flex-start,
  $alignitems: flex-start,
  $aligncontent: flex-start
) {
  margin-right: auto;
  margin-left: auto;
  padding: 0 $grid-margin-width-mb;
  box-sizing: border-box;
  @include flex-container($flexdirection, $flexwrap, $justifycontent, $alignitems, $aligncontent);
}

@mixin flex-container(
  $flexdirection: row,
  $flexwrap: wrap,
  $justifycontent: flex-start,
  $alignitems: flex-start,
  $aligncontent: flex-start
) {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-direction: $flexdirection;
  -ms-flex-direction: $flexdirection;
  flex-direction: $flexdirection;

  -webkit-flex-wrap: $flexwrap;
  -ms-flex-wrap: $flexwrap;
  flex-wrap: $flexwrap;

  -ms-flex-pack: $justifycontent;
  -webkit-justify-content: $justifycontent;
  -moz-box-pack: $justifycontent;
  justify-content: $justifycontent;

  -moz-box-align: $alignitems;
  -ms-flex-align: $alignitems;
  -webkit-align-items: $alignitems;
  align-items: $alignitems;

  -ms-flex-line-pack: $aligncontent;
  align-content: $aligncontent;
}

@mixin flex-element($order: 1, $flexgrow: 1, $flexshrink: 1, $flexbasis: auto) {
  -webkit-box-ordinal-group: $order;
  -moz-box-ordinal-group: $order;
  -webkit-order: $order;
  -ms-flex-order: $order;
  order: $order;

  -webkit-flex: $flexgrow $flexshrink $flexbasis;
  -ms-flex: $flexgrow $flexshrink $flexbasis;
  flex: $flexgrow $flexshrink $flexbasis;
}

// .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {

//   box-sizing: border-box;
//   padding: 0;

//   &:first-child{
//     //@extend .no-left-padding;
//     padding-left: 0;
//   }
//   &:last-child{
//     padding-right: 0;
//     //@extend .no-right-padding;
//   }
// }

// @include respond-above(sm){
//   .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
//     padding: 0 $grid-gutter-width-sm / 2;
//   }
// }

// @include respond-above(lg){

//   .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
//     padding: 0 $grid-gutter-width-md / 2;
//   }

// }

// .row {
//   margin-left: 0;
//   margin-right: 0;
// }

// .col-xs-12 {
//   @include flex-element(0, 0, 0, 100%);
// }
// .col-xs-11 {
//   @include flex-element(0, 0, 0, 91.66666667%);
// }
// .col-xs-10 {
//   @include flex-element(0, 0, 0, 83.33333333%);
// }
// .col-xs-9 {
//   @include flex-element(0, 0, 0, 75%);
// }
// .col-xs-8 {
//   @include flex-element(0, 0, 0, 66.66666667%);
// }
// .col-xs-7 {
//   @include flex-element(0, 0, 0, 58.33333333%);
// }
// .col-xs-6 {
//   @include flex-element(0, 0, 0, 50%);
// }
// .col-xs-5 {
//   @include flex-element(0, 0, 0, 41.66666667%);
// }
// .col-xs-4 {
//   @include flex-element(0, 0, 0, 33.33333333%);
// }
// .col-xs-3 {
//   @include flex-element(0, 0, 0, 25%);
// }
// .col-xs-2 {
//   @include flex-element(0, 0, 0, 16.66666667%);
// }
// .col-xs-1 {
//   @include flex-element(0, 0, 0, 8.33333333%);
// }

// @include respond-above(sm){

//   .col-sm-12 {
//     @include flex-element(0, 0, 0, 100%);
//   }
//   .col-sm-11 {
//     @include flex-element(0, 0, 0, 91.66666667%);
//   }
//   .col-sm-10 {
//     @include flex-element(0, 0, 0, 83.33333333%);
//   }
//   .col-sm-9 {
//     @include flex-element(0, 0, 0, 75%);
//   }
//   .col-sm-8 {
//     @include flex-element(0, 0, 0, 66.66666667%);
//   }
//   .col-sm-7 {
//     @include flex-element(0, 0, 0, 58.33333333%);
//   }
//   .col-sm-6 {
//     @include flex-element(0, 0, 0, 50%);
//   }
//   .col-sm-5 {
//     @include flex-element(0, 0, 0, 41.66666667%);
//   }
//   .col-sm-4 {
//     @include flex-element(0, 0, 0, 33.33333333%);
//   }
//   .col-sm-3 {
//     @include flex-element(0, 0, 0, 25%);
//   }
//   .col-sm-2 {
//     @include flex-element(0, 0, 0, 16.66666667%);
//   }
//   .col-sm-1 {
//     @include flex-element(0, 0, 0, 8.33333333%);
//   }
// }

// @include respond-above(lg){

//   .col-md-12 {
//     @include flex-element(0, 0, 0, 100%);
//   }
//   .col-md-11 {
//     @include flex-element(0, 0, 0, 91.66666667%);
//   }
//   .col-md-10 {
//     @include flex-element(0, 0, 0, 83.33333333%);
//   }
//   .col-md-9 {
//     @include flex-element(0, 0, 0, 75%);
//   }
//   .col-md-8 {
//     @include flex-element(0, 0, 0, 66.66666667%);
//   }
//   .col-md-7 {
//     @include flex-element(0, 0, 0, 58.33333333%);
//   }
//   .col-md-6 {
//     @include flex-element(0, 0, 0, 50%);
//   }
//   .col-md-5 {
//     @include flex-element(0, 0, 0, 41.66666667%);
//   }
//   .col-md-4 {
//     @include flex-element(0, 0, 0, 33.33333333%);
//   }
//   .col-md-3 {
//     @include flex-element(0, 0, 0, 25%);
//   }
//   .col-md-2 {
//     @include flex-element(0, 0, 0, 16.66666667%);
//   }
//   .col-md-1 {
//     @include flex-element(0, 0, 0, 8.33333333%);
//   }

// }

// @include respond-above(xl){

//   .col-lg-12 {
//     @include flex-element(0, 0, 0, 100%);
//   }
//   .col-lg-11 {
//     @include flex-element(0, 0, 0, 91.66666667%);
//   }
//   .col-lg-10 {
//     @include flex-element(0, 0, 0, 83.33333333%);
//   }
//   .col-lg-9 {
//     @include flex-element(0, 0, 0, 75%);
//   }
//   .col-lg-8 {
//     @include flex-element(0, 0, 0, 66.66666667%);
//   }
//   .col-lg-7 {
//     @include flex-element(0, 0, 0, 58.33333333%);
//   }
//   .col-lg-6 {
//     @include flex-element(0, 0, 0, 50%);
//   }
//   .col-lg-5 {
//     @include flex-element(0, 0, 0, 41.66666667%);
//   }
//   .col-lg-4 {
//     @include flex-element(0, 0, 0, 33.33333333%);
//   }
//   .col-lg-3 {
//     @include flex-element(0, 0, 0, 25%);
//   }
//   .col-lg-2 {
//     @include flex-element(0, 0, 0, 16.66666667%);
//   }
//   .col-lg-1 {
//     @include flex-element(0, 0, 0, 8.33333333%);
//   }

// }
