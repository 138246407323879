/*------------------------------------*\
  File: Homepage
\*------------------------------------*/

.front.page-node .content {
  /*
  ======================================================
   Block: Home General Styles
  ======================================================
  */
  h2.block-title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 132.3%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #202020;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;

    a {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 149.69%;
      letter-spacing: 0.02em;
      background-color: $bottom-banner-color;
      color: #ffffff;
      margin: 0 2rem;
      padding: 3px 10px;
      text-decoration: none;

      &::after {
        content: '\f178';
        font-family: $font-family-icon-fa;
        font-weight: 400;
        margin-left: 0.5rem;
      }

      &:focus,
      &:active,
      &:hover {
        background: lighten($bottom-banner-color, 7%);
      }
    }

    @include respond-below(md) {
      font-size: 25px;
      font-weight: 700;
      line-height: 1.3;
      justify-content: space-between;
      margin-top: 20px;

      a {
        font-size: 1.6rem;
        margin-left: 1rem;
      }
    }
  }

  /*
  ======================================================
   Block: Home Features
  ======================================================
  */
  .home-features {
    padding: 2rem 0;
    border-top: 3px solid #e6e6e6;
    display: inline-block;
    width: 100%;

    > ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0rem 1rem;

        &:not(:last-child) {
          border-right: 1px dashed #d5d5d5;
        }

        > div:first-child {
          @include respond-above(md) {
            height: 60px;
          }
          display: flex;
          align-items: center;

          img {
            max-height: 5.5rem;
            max-width: 9.5rem;
          }
        }

        h3,
        p {
          text-align: center;
        }

        h3 {
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 132.3%;
          color: #242424;
          margin-top: 10px;
        }

        p {
          font-size: 1.4rem;
          font-weight: 400;
        }
      }
    }

    @include respond-below(md) {
      border-top: none;

      > ul {
        border: 1px solid #c7c7c7;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
        background: #f9f9f9;
        li,
        li:not(:last-child) {
          width: 50%;
          border-right: 1px solid #c7c7c7;
          border-bottom: 1px solid #c7c7c7;
          padding: 2rem 1rem;

          p,
          img {
            display: none;
          }

          h3 {
            font-weight: 700;
            font-size: 20px;
            line-height: 132.3%;
            color: #24518a;
            margin: 0;
          }
        }
      }
    }
  }

  /*
  ======================================================
   Block: Home Categories
  ======================================================
  */

  .home-shopping-links {
    display: inline-block;
    width: 100%;
    @include respond-above(md) {
      display: none;
    }
    .home-shipping-link {
      a {
        background: #317ec4;
        border-radius: 3px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        height: 5rem;
        width: 100%;
        font-weight: 500;
        font-size: 25px;
        line-height: 132.3%;
        text-align: right;
        color: #ffffff;
        margin-bottom: 1.3rem;
        padding: 0 10px 0 0;
        align-items: center;
        &::before {
          content: '';
          background-image: url('../../images/icon-home-cat-1.png');
          background-color: #24518a;
          background-repeat: no-repeat;
          background-position: 10px center;
          width: 7rem;
          height: 100%;
          clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
        }

        @media screen and (max-width: 380px) {
          font-size: 22px;
        }
      }

      &.specials a::before {
        background-image: url('../../images/icon-home-cat-2.png');
      }
    }
  }
  .view-homepage-categories {
    border-top: 3px solid #e6e6e6;
    margin-top: 2rem;

    @include respond-below(md) {
      border-top: none;
      margin-top: 0;
      .view-header {
        display: none;
      }
    }
    .view-content {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      column-gap: 4rem;
      row-gap: 3.5rem;

      @include respond-below(xl) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @include respond-below(lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      > .views-row {
        border: 1px solid #d5d5d5;
        box-sizing: border-box;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);

        .views-field {
          height: 100%;
          .field-content {
            > a {
              height: 100%;
              width: 100%;
              display: inline-block;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 2rem;

              &:hover img {
                transform: scale(1.1);
              }

              img {
                max-height: 171px;
                width: 218px;
                object-fit: contain;
                transition: transform 0.3s ease-in;
                padding: 1rem 0;
              }

              h3 {
                margin-top: auto;
                padding-top: 10px;
                font-weight: 700;
                font-size: 20px;
                line-height: 132.3%;
                text-align: center;
                color: #3f3f3f;
              }

              @include respond-below(lg) {
                h3 {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      @include respond-below(md) {
        display: flex;
        flex-direction: column;
        row-gap: 1.3rem;

        > .views-row {
          .views-field {
            .field-content {
              > a {
                background: #317ec4;
                border-radius: 3px;
                padding: 0;
                flex-direction: row;
                justify-content: space-between;
                height: 5rem;

                &::before {
                  content: '';
                  background-image: url('../../images/icon-home-cat-1.png');
                  background-color: #24518a;
                  background-repeat: no-repeat;
                  background-position: 10px center;
                  width: 7rem;
                  height: 100%;
                  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
                }

                img {
                  display: none;
                }
                h3 {
                  font-weight: 500;
                  font-size: 25px;
                  line-height: 132.3%;
                  text-align: right;
                  color: #ffffff;
                  margin: 0;
                  padding: 0 10px 0 0;
                  white-space: nowrap;
                }
              }
            }
          }

          &:nth-child(3n - 2) .views-field {
            .field-content > a:before {
              background-image: url('../../images/icon-home-cat-3.png');
            }
          }

          &:nth-child(3n - 1) .views-field {
            .field-content > a:before {
              background-image: url('../../images/icon-home-cat-1.png');
            }
          }

          &:nth-child(3n) .views-field {
            .field-content > a:before {
              background-image: url('../../images/icon-home-cat-2.png');
            }
          }
        }
      }

      @media screen and (max-width: 380px) {
        > .views-row .views-field .field-content > a h3 {
          font-size: 22px;
        }
      }
    }
  }

  /*
  ======================================================
   Block: Home Specials 
  ======================================================
  */
  .home-specials-desktop{
    position: relative;
    padding: 0 4rem;
    @include respond-below(md){
      display: none;
    }
    .view-content{
      display: grid;
      grid-gap: 4rem;
      grid-template-columns: repeat(5, minmax(0, 1fr));     

      @include respond-below(xxl){
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @include respond-below(xl){
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      > .views-row{
        > .node-product-collection.view-mode-teaser,
        > .node-product.node-teaser{
          max-width: 100%;
        }
      }
    }
    .pager {
      .pager-current {
        display: none;
      }
      .pager-previous a,
      .pager-next a {
        font-size: 0;
        border: 0;
        position: absolute;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 50px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        &:hover {
          opacity: 0.5;
        }
      }

      .pager-previous a {
        background-image: url('../../images/icon-prev.svg');
        left: -10px;
      }
      .pager-next a {
        background-image: url('../../images/icon-next.svg');
        right: -10px;
      }
    }
  }
  .home-specials-mobile{
    position: relative;
    .view-content{
      position: relative;
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      .views-row{
        display: flex;
        justify-content: center;
      }
    }
    @include respond-above(md){
      display: none;
    }
  }
  .home-specials-desktop,
  .home-specials-mobile{
    .pager{
      > .pager-current{
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  /*
  ======================================================
   Block: Home Brands
  ======================================================
  */
  .view-homepage-brands {
    border-top: 3px solid #e6e6e6;
    margin-top: 4rem;
    margin-bottom: 6rem;

    @include respond-below(md) {
      border-top: none;
      margin-top: 3rem;
      .slick__slider {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 4rem;
        column-gap: 4rem;
        > .slick__slide {
          display: block;
        }
      }
      .slick__arrow {
        display: none;
      }
    }

    .view-content {
      margin: 0 auto;
      // max-width: 120rem;

      /** Carousel design **/
      .slide__content {
        height: 7rem;
        > a {
          max-width: 175px;
          max-height: 100%;
          display: flex;
          align-items: center;
        }
      }

      /** Grid design **/
      // table {
      //   width: 100%;

      //   tr {
      //     display: flex;
      //     justify-content: space-between;
      //     margin: 0 -2rem;

      //     td {
      //       padding: 2.5rem 2rem;

      //       a {
      //         width: 175px;
      //         height: 110px;
      //         max-width: 100%;
      //         display: inline-block;
      //         &:hover img {
      //           transform: scale(1.1);
      //         }
      //         img {
      //           height: 100%;
      //           width: 100%;
      //           object-fit: contain;
      //           transition: transform 0.3s ease-in;
      //         }
      //       }
      //     }

      //     @include respond-below(lg) {
      //       flex-wrap: wrap;
      //       margin: 0;

      //       td {
      //         width: 50%;
      //         text-align: center;
      //       }
      //     }

      //     @include respond-below(md) {
      //       td {
      //         padding: 1rem;
      //         a {
      //           width: 102px;
      //           height: 74px;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  /*
  ======================================================
   Block: Home Quick Links
  ======================================================
  */
  .home-quick-links {
    display: inline-block;
    width: 100%;
    margin-top: 2rem;
    @include respond-below(md) {
      margin-top: 1rem;
    }
    .view-content {
      // display: grid;
      // grid-template-columns: 360px auto 360px;
      // grid-template-rows: repeat(4, 240px);
      // max-width: 120rem;
      // margin: 0 auto;
      display: grid;
      width: 100%;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 15px;

      @include respond-below(md) {
        column-gap: 4px;
      }

      // @include respond-below(md) {
      //   display: flex;
      //   flex-direction: column;
      // }

      //   .views-row {
      //     height: 19.6rem;
      //   }
      // }
    }

    .views-row {
      position: relative;
      height: 17.4rem;
      // &:nth-child(8),
      // &:nth-child(5),
      // &:nth-child(4),
      // &:first-child {
      //   grid-column-end: span 2;
      // }

      @include respond-below(md) {
        height: 11.3rem;
      }

      .node-home-quick-links {
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        overflow: hidden;

        &:hover img {
          transform: scale(1.1);
        }
      }
      .field-name-field-home-quick-link-image {
        height: 100%;
        width: 100%;
        display: inline-block;

        .field-items {
          height: 100%;
          > .field-item {
            height: 100%;
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
        }
      }

      .field-name-field-home-quick-link {
        a {
          text-decoration: none;
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          font-weight: 700;
          font-size: 4rem;
          line-height: 132.3%;
          text-align: center;
          color: #ffffff;

          @include respond-below(xl) {
            font-size: 3.5rem;
          }

          @include respond-below(lg) {
            font-size: 3rem;
          }

          @include respond-below(md) {
            font-size: 2rem;
          }

          @include respond-below(sm) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
