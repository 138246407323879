.page-cart {
  /*
  ======================================================
  If not logged in
  ======================================================
  */
  .content {
    //Review Page
    .order-review-table {
      width: 100%;
      font-size: 14px;

      td {
        padding-top: 15px;
        padding-bottom: 15px;

        .table-responsive {
          margin: 0 15px;
        }

        &.title-col {
          padding-right: 15px;
        }
      }

      tr {
        &.review-button-row {
          td {
            padding: 15px;
          }
        }
      }
    }

    //Checkout Page

    .gss-2days-label {
      font-weight: bold;

      &::before {
        //content:"\f073";
        content: '\f0d1';
        font-weight: normal;
        font-family: $font-family-icon-fa;
        color: $orange-3;
        margin-right: 4px;
        @include wrapper(relative);
      }
    }

    .gss-normal-label {
      font-weight: bold;
      &::before {
        content: '\f0d1';
        font-weight: normal;
        font-family: $font-family-icon-fa;
        color: $orange-3;
        margin-right: 4px;
        @include wrapper(relative);
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    .form-control {
      margin-bottom: 0;
      width: auto;
    }

    textarea.form-control {
      @include wrapper(relative, 100%);
    }

    /*
    ======================================================
     uc-cart-checkout-form
    ======================================================
    */
    form.uc-cart-checkout-form {
      /*
      ======================================================
       Delivery Pane
      ======================================================
      */
      // #delivery-pane {
      //   @include respond-above(md) {
      //     display: flex;
      //     padding-right: 60px;
      //     padding-bottom: 0;
      //     margin-bottom: 0;
      //   }

      //   .address-pane-table {
      //     table {
      //       > tbody {
      //         > tr {
      //           @include respond-below(lg) {
      //             display: flex;
      //             flex-direction: column;
      //             width: 100%;
      //             > td {
      //               &:first-child {
      //                 padding-bottom: 0px;
      //               }
      //               padding-bottom: 20px;
      //             }
      //           }
      //           > td {
      //             padding-bottom: 20px;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }

      /*
      ======================================================
       Quotes Pane
      ======================================================
      */
      // #quotes-pane {
      //   display: flex;

      //   button.form-submit {
      //     padding: 15px;
      //     min-width: 300px;
      //   }
      // }

      /*
      ======================================================
       Comments Pane
      ======================================================
      */
      // #comments-pane {
      // @include respond-above(md) {
      //   padding-right: 60px;
      // }

      // display: block;
      // border: none;

      // .panel-title {
      //   font-size: 0;
      //   display: block;
      //   &::after {
      //     content: 'Add Order Comments';
      //     font-size: 17px;
      //   }
      // }

      // .panel-body {
      //   padding-top: 0;
      //   padding-bottom: 0;
      // }
      // }

      table {
        &.cart-review {
          tr {
            th {
              &.price {
                text-align: center;
                width: 10%;
              }
            }

            td {
              &.price {
                text-align: right;
              }

              padding-top: 15px;
              padding-bottom: 15 px;
            }

            td,
            th {
              font-weight: bold;
              &.qty {
                width: 10%;
                text-align: left;
              }

              &.products {
                width: 80%;
                text-align: left;
              }

              &.subtotal {
                span {
                  &#subtotal-title {
                    padding-right: 20px;
                  }
                  &.uc-price {
                    display: inline-flex;
                  }
                }
              }
            }
          }
        }
      }

      > div,
      .fieldset,
      legend {
        @include wrapper(relative, 100%);

        margin: 0;
      }

      .panel-heading,
      .panel-body {
        @include wrapper(relative, 100%);
        display: block;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }

      .panel-body {
        .form-item.form-item-panes-payment-payment-method {
          &.form-type-radio {
            display: inline-block;
          }
        }

        .form-submit {
          margin-left: 0;
          position: relative;
          margin-bottom: 15px;
        }

        .progress-wrapper.ajax-progress.ajax-progress-bar {
          @include wrapper(relative, 100%);
          @include flex-container(column, wrap);

          margin-bottom: 15px;

          div {
            @include wrapper(relative, 100%);
            margin: 0;
            padding: 0;
          }
        }
      }

      .panel-heading {
        padding: 0 0 15px 0;
        margin: 0;
        border-bottom: 1px solid $grey-4;
      }

      #payment-details,
      #quote {
        border-color: $grey-4;
      }

      #payment-details {
        position: relative;
        font-size: 0;
        &::before {
          position: relative;
          padding: 15px 0;
          content: 'Click Place Order to complete payment.';
          font-size: 15px;
        }
      }

      .form-item-panes-delivery-select-address {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        @include respond-above(sm) {
          flex-direction: row;
          justify-content: space-between;
        }

        .input-group {
          .form-control.form-select {
            width: 100%;
          }
        }

        .input-group-addon {
          display: none;
        }

        .control-label {
          display: flex;
          align-items: center;
          @include respond-above(sm) {
            width: 20%;
          }
          @include respond-between(sm, lg) {
            width: 40%;
          }
          width: 100%;
        }

        .form-select,
        .input-group {
          @include respond-above(sm) {
            width: 70%;
          }
          @include respond-between(sm, lg) {
            width: 60%;
          }
          width: 100%;
        }
      }

      // .form-control {
      //   &:not(.chosen-container-single) {
      //     &.form-text,
      //     &.form-select {
      //       padding-top: 12px !important;
      //       padding-bottom: 12px !important;
      //       height: auto;
      //     }
      //   }
      // }

      .chosen-container-single {
        height: auto;
        width: 100% !important;
        position: relative;
      }

      .chosen-single {
        padding-top: 12px;
        padding-bottom: 12px;
        height: auto;
        @include wrapper(relative, 100%);
      }

      .chosen-single,
      .form-control,
      .form-type-select {
        border-radius: 0;
        width: 100%;
      }

      table {
        @include wrapper(relative, 100%);
        tbody {
          > tr {
            td {
              &.field-label {
                text-align: left;
              }
            }
          }
        }
      }

      .form-group {
        // width: 100%;

        &.form-type-textfield.form-item-panes-customer-primary-email {
          @include respond-above(sm) {
          }
          width: 100%;

          @include flex-container(row, wrap, flex-start, stretch, center);
          label,
          input {
            display: flex;
            width: auto;
          }

          input[type='text'],
          textarea {
            border-radius: 0;
          }

          label {
            display: flex;
            margin: 0;
            text-align: left;
            align-items: center;
            padding-bottom: 8px;
            @include respond-above(lg) {
              width: 20%;
            }
            @include respond-between(sm, lg) {
              width: 40%;
            }
            width: 100%;
          }

          input[type='text'] {
            @include respond-above(lg) {
              width: 70%;
            }
            @include respond-between(sm, lg) {
              width: 60%;
            }
            width: 100%;
          }
        }
      }
    } // end #uc-cart-checkout-form

    table {
      tbody,
      tr {
        td {
          color: $black;
          vertical-align: middle;

          a {
            color: inherit;
          }

          &.subtotal {
            background-color: $white;
          }

          &.desc {
            font-weight: 500;
          }

          &.image {
            a {
              position: relative;
            }
            img {
              position: relative;
              margin: 0 auto;
            }
          }
        }
      }
    }

    #uc-wishlist-uc-cart-view-form {
      .form-actions {
        text-align: right;
        margin-bottom: 30px;

        a {
          margin: 0 10px;
        }
      }
      table.table {
        min-width: 320px;
        margin: 0;
      }

      table {
        thead {
          th {
            &:first-child {
              width: 10%;
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid $white !important;
          }
        }
      }

      .table-striped {
        // th:nth-child(2),
        // td:nth-child(2) {
        //   display: none;

        //   @include respond-above(xs) {
        //     display: block;
        //   }
        // }

        td:nth-child(3) {
          text-align: left;
        }

        td:nth-child(4) {
          input {
            margin: auto;
            min-width: 40px;
            max-width: 50px;
            text-align: center;
          }
        }

        td:nth-child(5),
        th:nth-child(5),
        tr:last-child td {
          text-align: right;
        }

        .btn-danger {
          font-size: 0;

          span {
            font-size: 14px;
          }

          @include respond-above(xs) {
            font-size: 14px;
            margin-right: 0.25em;
          }
        }
      }
    }
  }

  // #quote {
  //   @include wrapper(relative, 100%);
  //   display: flex;
  //   flex-direction: column;
  //   border: none;
  //   padding: 0;
  //   margin: 0;
  //   //padding-top: 15px;
  //   //padding-bottom: 15px;
  //   @include respond-above(sm) {
  //     flex-direction: row;
  //   }

  //   // Special
  //   .form-item {
  //     //margin-top: 15px;
  //     //margin-bottom: 15px;
  //     @include wrapper(relative, 100%);
  //     @include flex-container(column, wrap);

  //     @include respond-above(sm) {
  //       @include wrapper(relative, 300px); //392px
  //       min-height: 200px;
  //       //min-height: 320px;
  //       &:not(:first-child) {
  //         margin-left: 20px;
  //       }
  //     }

  //     input[type='radio'] {
  //       display: none;
  //     }

  //     &.q-select {
  //       label {
  //         border: 5px solid #ec6808;
  //       }
  //     }

  //     .ajax-progress.ajax-progress-throbber {
  //       display: none;
  //     }

  //     label {
  //       @include wrapper(relative, 100%);
  //       @include flex-container(column, wrap);
  //       * {
  //         @include wrapper(relative, 100%);
  //       }
  //       img {
  //         position: relative;
  //         width: calc(100% + 30px);
  //         margin-left: -15px;
  //         margin-right: -15px;
  //         margin-bottom: 15px;
  //       }

  //       border: 2px solid #e1e1e1;
  //       height: 100%;
  //       padding: 15px;
  //       //> div{
  //       //  padding: 15px;
  //       //}
  //     }
  //   }
  // }

  #uc-order-total-preview .line-item-total {
    td {
      vertical-align: top;
      &.price {
        .uc-price {
          font-weight: bold;
        }
      }
    }
  }

  td.subtotal {
  }
  /*
    ======================================================
      KODAWEB2021
    ======================================================
  */
  #uc-coupon-form {
    > div {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      align-items: center;

      @include respond-below(lg) {
        flex-direction: column;
        align-items: flex-start;
      }
      h3 {
        width: 100%;
      }
      .form-item-code {
        display: flex;
        align-items: center;
        margin: 10px 10px 10px 0;
        label {
          margin-right: 10px;
        }
      }

      .form-item-coupons {
        display: flex;
        margin: 0 10px 0 30px;
        align-items: center;

        @include respond-below(lg) {
          margin-left: 0;
        }
        label {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
  form#uc-customized-cart-pane-uc-cart-view-form {
    .uc-cart-item-uc-cart-item {
      display: none;
    }
    table.table-striped {
      min-width: 500px;
      tbody,
      tr {
        border-bottom: 1px solid $white;

        td {
          &.image {
            display: flex;
            flex-direction: column;
            a {
              margin-bottom: 5px;
              display: inline-block;
            }
          }

          &.desc {
            ul {
              padding-left: 15px;
              li {
                padding: 5px 0;
              }
            }

            @include respond-below(md) {
              font-size: 12px;
            }
          }

          &.remove {
            @include respond-below(md) {
              padding-left: 10px;
              button.btn-danger {
                padding: 3px 5px;
                font-size: 12px;

                span.icon {
                  font-size: 11px;
                  margin-right: 3px;
                }
              }
            }
          }

          &.price {
            .price-suffixes {
              margin-left: 5px;
            }
            @include respond-below(md) {
              padding: 0 10px;
            }
          }

          &.qty {
            input {
              margin: auto;
              min-width: 40px;
              max-width: 50px;
              text-align: center;
            }
          }

          &.subtotal {
            .price-suffixes {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .form-actions {
    .btn.btn-info {
      @include btn($menu-color);
      margin-left: 10px;
    }

    .btn.btn-default {
      @include btn($orange-3);
      margin-left: 10px;
    }

    @include respond-below(sm) {
      display: flex;
      flex-direction: column;
      text-align: center;
      a,
      button {
        margin-bottom: 10px;
      }
    }
  }
}
