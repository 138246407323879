/*------------------------------------*\
  Style
\*------------------------------------*/
@import './base/variables';
@import '../bootstrap/scss/bootstrap';
@import './mixins/responsive';
@import './mixins/grid';
@import './mixins/animation';
@import './mixins/effects';
@import './mixins/elements';

@import './base/base';
@import './base/typography';

// Components
@import './components/slick';
@import './components/button';
@import './components/checkbox';

@import './components/overrides';
@import './components/mega-menu';
@import './components/mega-menu-2';
@import './components/mm-panel';
@import './components/modal-default';
@import './components/jquery.scrollbar';

// Blocks
@import './components/blocks/block-facetapi';
@import './components/blocks/block-header-search';
@import './components/blocks/block-uc-cart';
@import './components/blocks/easy-breadcrumb';
@import './components/blocks/my-account-block';
@import './components/blocks/top-banner-contact-details';
@import './components/blocks/top-banner-menu';
@import './components/blocks/uc-cart-checkout-form';
@import './components/blocks/block-sharethis';
@import './components/blocks/block_masquerade';
// Node
@import './components/node/node-home-slide-show';
@import './components/node/node-product';

// View
@import './components/view/view-account-orders';
@import './components/view/view-brands';
@import './components/view/view-home-specails-list';
@import './components/view/view-homepage-brands';
// Groups
@import './components/groups/group-title-section';

@import './components/product-teaser';
@import './components/product-bundle';
@import './components/slide-menu';
// Page
@import './pages/home';
@import './pages/cart-page';
@import './pages/contact-us-page';
@import './pages/login-page';
@import './pages/orders-page';
@import './pages/user-wish-list-';
@import './pages/wish-list';
@import './pages/news';
@import './pages/testimonials';
@import './pages/product';
@import './pages/products';
@import './pages/product-collection';
@import './pages/product-comparison';
@import './pages/quick-order';
@import './pages/specials';
@import './pages/page-checkout';
@import './pages/price-match';
@import './pages/confirmation';
@import './pages/brands';
@import './pages/product-categories';

// Layouts
@import './layouts/layout';
@import './layouts/region/content';
@import './layouts/region/region-copyright';
@import './layouts/region/region-topmenu';
@import './layouts/header';
@import './layouts/footer';
@import './layouts/content-bottom';

@import './theme/admin';
@import './global';
