.filters-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 132.3%;
  /* or 33px */

  color: #202020;
}
.block-facetapi {
  text-transform: uppercase;

  h2 {
    font-size: 14px;
    font-weight: bold;
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  @include respond-below(lg) {
    padding: 1rem;
    border-top: 1px dashed #c6c6c6;
    &:last-child {
      border-bottom: 1px dashed #c6c6c6;
    }
    h2 {
      font-size: 1.8rem;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul,
    #facetapi-facet-search-apiproduct-and-product-collection-index-block-sell-price-wrapper {
      display: none;
    }

    .collapse-trigger {
      font-size: 2rem;
      font-weight: 400;
      padding: 1rem;
      &::before {
        content: '\002B';
      }

      &.expanded::before {
        content: '\2212';
      }
    }
  }
  .collapse-trigger {
    @include respond-above(lg) {
      display: none;
    }
  }

  li {
    list-style: none;
    padding: 2px 0;
  }

  li.expanded {
    margin-left: 0;
    //margin-left: -30px;
  }

  input.facetapi-checkbox {
    margin-right: 5px;
  }

  label,
  a {
    color: $black;
    margin-right: 0;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
  }

  .facetapi-limit-link {
    text-decoration: none;
    font-weight: 500;

    &::before {
      content: '\f103';
      margin-right: 8px;
      color: $orange;
      font-family: $font-family-icon-fa;
      @include wrapper(relative, 24px);
    }

    &.open {
      &::before {
        content: '\f102';
      }
    }
  }

  /** Filter by Price **/
  #facetapi-facet-search-apiproduct-and-product-collection-index-block-sell-price-wrapper {
    .reset-link {
      @extend .btn-secondary;
      @extend .btn-small;
      width: calc(100% - 35px);
      margin-bottom: 10px;
    }
    .ajax-facets-controls-wrapper {
      padding-right: 45px;
      position: relative;
      > p {
        width: 108px;
        display: flex;
        align-items: center;
        &:first-child {
          float: left;
          input {
          }
        }

        &:nth-child(2) {
          position: absolute;
          top: 0;
          right: 0px;
          input {
          }

          label {
            &::before {
              content: 'Max: $';
            }
          }
        }
        label {
          font-size: 0;
          margin: 0;
          top: 0;
          &::before {
            content: 'Min: $';
            font-size: 14px;
          }
        }
        input {
          width: 58px;
          border: none;
          outline: none;
          background: none;
          display: inline-block;
          font-size: 14px;
        }
      }
      > .slider-wrapper {
        display: inline-block;
        width: 100%;
        border: none;
        background: none;
        height: 11px;
        &::before {
          content: '';
          position: absolute;
          height: 2px;
          top: 3px;
          background-color: #c5c5c5;
          display: block;
          right: 0;
          left: 0;
        }
        .ui-slider-range {
          height: 2px;
          top: 3px;
          background: #00adee;
        }

        .ui-slider-handle {
          background: #24518a;
          width: 11px;
          margin: 0;
          border: 0;
          cursor: pointer;
          border-radius: 0;
          clip-path: polygon(0% 0%, 100% 0, 99% 75%, 50% 100%, 0 75%);

          &:last-child {
            margin-left: -11px;
          }
        }
      }
    }
  }
}
