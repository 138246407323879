.admin-menu section.mega_menu {
  > .container {
    top: 149px;

    @media screen and (max-width: 1024px) {
      top: 167px;
    }
  }

  &.collapsed > .container {
    top: 114px;
  }
}
section.mega_menu {
  display: none;
  height: auto; //48px

  @include respond-above(lg) {
    display: block;
  }

  > .container {
    background-color: $menu-color;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 120px;
  }

  &.collapsed > .container {
    top: 85px;
  }
}

.region-mega-menu {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .block-koda-custom-menu {
    display: inline-block;
    position: static;


  }

  #block-menu-menu-secondary-menu {
    padding: 12px 14px;
    @include respond-below(xxl) {
      padding: 12px 5px;
    }
    ul.menu{
      display: flex;
      height: 100%;
      align-items: center;
      >li {
        >a {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          color: #FFFFFF;
          padding: 0 14px;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;

          }

          @include respond-below(xxl) {
            padding: 0 5px;
            font-size: 13.7px;
          }

          @include respond-below(xl) {
            padding: 0 3px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

#app-custom-menu {
  .react-menu {
    .react-menu-bar {
      padding-right: 14px;
      border-right: 2px solid rgba(255, 255, 255, 0.5);
      @include respond-below(xxl) {
        padding-right: 7px;
      }
    }
  }
}


#app-custom-brand-menu,
#app-custom-menu {
  height: 100%;
  .react-menu {
    font-size: 10px;
    padding: 12px 0;
    height: 100%;

    .react-menu-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-left: 14px;
      height: 100%;

      @include respond-below(xxl) {
        padding-left: 7px;
      }
    }  
    
    .react-menu-item {
      padding: 0 14px; // minimum padding between menu items

      .top-menu-link {
        color: $white;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        display: inline-block;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }

      &.has-children {
        .top-menu-link {
          &::after {
            color: $white;
            content: '\f107';
            margin-left: 7px;
            font-family: $font-family-icon-fa;
            font-weight: normal;
            font-size: 14px;

            @include respond-below(xxl) {
              margin-left: 4px;
            }
          }
        }
      } // children

      @include respond-below(xxl) {
        padding: 0 5px;
        .top-menu-link {
          font-size: 13.7px;
        }
      }

      @include respond-below(xl) {
        padding: 0 3px;

        .top-menu-link {
          font-size: 12px;
        }
      }
    }

    .this-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }

    .react-menu-dropdown {
      background-color: $white;
      left: 0;
      padding: 20px;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 100;
      max-height: 428px;
      overflow: auto;
      box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;

      a {
        color: $black;
      }

      .react-menu-option {
        .img-wrapper img {
          @include myanimated;
        }
        &:hover {
          .img-wrapper {
            overflow: hidden;
            img {
              @include scale(1.08);
            }
          }
        }
      }
    }

    .dropdown-wrapper {
      display: flex;
      flex-direction: row;
        
    }

    .react-menu-option {
      min-width: 12.5%;
      position: relative;
      width: 160px;
      padding: 5px;
      margin-bottom: 15px;

      > a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    .img-wrapper {
      position: relative;
      height: 160px;
      width: 100%;
      padding: 10px;
      text-align: center;


      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }

      svg {
        bottom: 5px;
        position: absolute;
        right: 0px;
        width: 24px;
      }
    }

    .item-name {
      width: 100%;
      text-align: center;
    }

    .parent-item {
      border-right: 2px solid lightgray;
      margin-right: 20px;

      > div {
        margin: 5px 0;
        text-align: center;
      }

      .react-menu-option {
        padding: 0px;
      }

      .img-wrapper img {
        height: 100px;
        width: 100px;
      }

      .react-menu-option {
        width: 140px;
      }
    }

    .back-link {
      svg {
        width: 40px;
      }
    }

    .home-link {
      padding-right: 10px;     
      padding-top: 5px;

      svg {
        width: 24px;

        path {
          fill: white;
        }
      }

      @include respond-below(xxl) {
        padding-right: 6px;
        svg {
          width: 20px;

        }
      }
    }
  }
}

#app-custom-brand-menu {
  .react-menu-item .top-menu-link {

    @include respond-above(xl) {
      min-width: 129px;
    }

    @include respond-above(xxl) {
      min-width: 147px;
    }
  }

  .react-menu .this-items {
    
    .react-menu-option {
      .img-wrapper {
        padding: 18px;
        max-width: 180px;
        display: flex;
      }
    }
  }
}

#app-custom-brand-menu,
#app-custom-menu,
#app-custom-menu-2 {
  a:hover,
  .top-menu-link:hover,
  .item-name:hover,
  .img-wrapper:hover {
    cursor: pointer;
  }

  .react-menu-item {
    text-transform: uppercase;
  }
}
