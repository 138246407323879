.hamburger {
  cursor: pointer;
  span {
    background: #fff;
  }

  &.is-active span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);

    &:nth-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
}
/*------------------------------------*\
  Slide Menu
\*------------------------------------*/
.slide-menu {
  overflow: auto;
  @include animated;
  left: -100%;
  top: 0;
  z-index: 1040;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #24518a;
  color: #fff;

  &--site-title {
    padding: 15px;
    // background-color: $color-secondary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: #fff;
      margin: 0;
    }
  }

  @include respond-above(lg) {
    display: none;
  }

  &.open {
    left: 0;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .region-mobile-menu {
    display: inline-block;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;

    h2.block-title {
      display: none;
    }
  }

  .mobile-menu-top-links {
    .mobile-menu-link {
      width: 100%;
      margin-bottom: 13px;
      a {
        font-weight: 500;
        font-size: 25px;
        line-height: 132.3%;
        padding: 10px 14px;
        text-align: right;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: #317ec4;
        border-radius: 3px;

        &::before {
          content: '';
          width: 30px;
          height: 30px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &.brand a::before {
        background-image: url('../../images/icon-link-brand.png');
      }

      &.specials a::before {
        background-image: url('../../images/icon-link-specials.png');
      }
    }
  }
  ul.menu {
    position: relative;
    width: 100%;
    height: 100%;
    li {
      width: 100%;
      a {
        color: #fff;
        font-size: 22px;
        line-height: 100%;
        font-weight: 500;
        padding: 13px 14px;

        &:hover {
          background: none;
          text-decoration: underline;
        }
      }

      // dropdown : general
      .dropdown-menu {
        position: relative;
        width: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      /** Expanded **/
      &.expanded {
        position: relative;

        .expand-menu {
          @include wrapper(absolute, 20px, 30px);
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          right: 4px;
          outline: none;
          z-index: 10;
          margin-top: 10px;

          &:active,
          &:hover,
          &:focus {
            outline: none;
            background-color: unset;
          }

          &::before {
            @include animated;
            font-size: 18px;
            content: '\f107';
            font-weight: normal;
            font-family: $font-awesome;
            color: #fff;
          }
        }
      }

      /** Open **/
      &.open {
        > a {
          background-color: unset;
          &.expand-menu {
            &::before {
              -moz-transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              -o-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
        }

        > .dropdown-menu {
          display: block;
          border-left: 8px solid #007ec3;
          > li {
            &.active {
              a {
                color: #fff;
                background-color: unset !important;
              }
            }

            > a {
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
            }

            .dropdown-menu {
              border-left: 8px solid lighten(#007ec3, 20%);
            }
          } // li
        } // ul
      }
    }
  }
}
