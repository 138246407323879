.admin-menu header.header .header_wrapper {
  top: 29px;

  @media screen and (max-width: 1024px) {
    top: 47px;
  }
}
header {
  &.header {
    .header_wrapper {
      position: fixed;
      background-color: $white;
      width: 100%;
      z-index: 110;
      top: 0;
    }

    .topmenu .container {
      float: left;
    }
    .region-topmenu {
      display: flex;
      align-items: center;
      padding: 15px;
      transition: all 0.3s;
      height: 12rem;
      position: relative;

      @include respond-below(lg) {
        // height: 9.7rem;
        height: 7rem;
        padding: 1rem 3rem 1rem 0;
        // margin-bottom: 4.3rem;
        margin-bottom: 12.4rem;
      }

      @include respond-below(md) {
        margin-bottom: 4.5rem;

        &.search-bar-show {
          margin-bottom: 12.4rem;

          .block-header-search {
            display: block;
          }
        }
      }
    }

    &.collapsed {
      .header_wrapper {
        border-bottom: 1px solid $grey-1;
      }

      @include respond-above(lg) {
        .region-topmenu {
          height: 8.5rem;
          padding: 1rem;
        }

        .block-header-logo {
          flex-basis: 39.3rem;
        }
      }
    }

    .block-header-logo {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
      flex-shrink: 1;
      flex-basis: 55rem;
      min-width: 27rem;

      a.logo-link {
        display: inline-block;
        text-decoration: none;

        img {
          max-width: 100%;
          max-height: 6.3rem;
          height: auto;
          margin-bottom: 1rem;
        }

        @include respond-above(md) {
          .logo-mobile {
            display: none;
          }
        }

        @include respond-below(md) {
          img {
            display: none;
          }

          .logo-mobile {
            display: flex;
            flex-direction: column;
            color: $menu-color;
            font-weight: 700;
            text-transform: uppercase;
            font-size: clamp(16px, 6.5vw, 28px);
            line-height: 30px;
          }
        }
      }

      @include respond-below(lg) {
        // padding-left: 25px;
        min-width: 12rem;
        flex-basis: 39.3rem;
        margin-right: auto;
        height: 4.4rem;
        img {
          max-height: 4rem;
          margin-bottom: 0;
        }
        a.header__phone {
          display: none;
        }
      }
    }

    a.header__phone {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.3;
      color: #3f3f3f;
      text-decoration: none;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        background-image: url(../../images/icon-phone.svg);
        background-repeat: no-repeat;
        display: inline-block;
        width: 1.6rem;
        height: 2.3rem;
        margin-right: 5px;
      }
    }

    .block-header-search {
      flex-basis: 64.5rem;
      min-width: 40rem;
      flex: 1;
      margin: 0 4rem 0 5rem;

      @include respond-below(xl) {
        margin: 0 1rem;
        min-width: 30rem;
      }

      @include respond-below(lg) {
        position: absolute;
        left: 0;
        width: 100%;
        // max-width: 400px;
        min-width: unset;
        margin: 0;
        bottom: -111px;
      }

      @include respond-below(md) {
        display: none;
      }
    }

    .user-menu {
      position: relative;
      padding: 0 5px;
      .menu-block-account-name {
        position: absolute;
        bottom: 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;
        min-width: 55px;
        text-align: center;
        color: #292929;
        z-index: -1;
      }
      h2 {
        display: none;
      }

      @include respond-below(md) {
        // padding-top: 12px;
        .menu-block-account-name {
          font-weight: 500;
          font-size: 10px;
          line-height: 130.19%;
          text-decoration: none;
          text-align: center;
          color: #161616;
          min-width: unset;
          width: 100%;
        }
      }

      .menu.nav {
        > li {
          font-size: 0;

          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0;
          cursor: pointer;

          > a {
            width: 5.5rem;
            height: 5.5rem;
            padding: 0 1.5rem;

            &::before {
              content: '';
              background-image: url('../../images/user.svg');
              background-repeat: no-repeat;
              width: 2.5rem;
              height: 2.8rem;
              display: inline-block;
              background-position-y: 3px;
            }

            @include respond-below(md) {
              width: 2.4rem;
              height: 2.4rem;
              padding: 0 0px 0 2px;

              &::before {
                width: 2.2rem;
                height: 2.4rem;
                background-size: 2rem auto;
                background-position-y: 0;
              }
            }

            &:focus,
            &:hover {
              background-color: transparent;
            }

            .caret {
              display: none;
            }
          }

          .dropdown-menu {
            display: none;
            position: absolute;
            top: 95%;
            right: 0;
            left: auto;
            z-index: 1000;
            min-width: 100px;
            padding: 5px 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
          }

          &:hover .dropdown-menu {
            display: block;
          }
        }
      }
    }

    .user-menu {
      margin-left: 30px;
      min-width: 40px;
      @include respond-below(xxl) {
        margin-left: 10px;
      }

      @include respond-below(xl) {
        margin-left: 0;
        height: 5rem;
      }

      @include respond-below(md) {
        margin-left: 7px;
        height: 4rem;
      }

      @include respond-below(sm) {
        margin-left: 3px;
        padding: 0;
      }
    }
    .block-product-comparison,
    .block-quick-order {
      position: relative;
      margin-right: 30px;

      .link-wrapper > a {
        display: flex;
        flex-direction: column;
        color: #292929;
        text-decoration: none;
        align-items: center;
        justify-content: space-between;
        width: 9.4rem;
        height: 5.5rem;

        img {
          height: 2.8rem;
          width: auto;
        }

        span {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.6rem;
          letter-spacing: 0.02em;
          padding-top: 0.5rem;
        }

        span#product-comparison-number,
        span#quick-order-cart-number {
          position: absolute;
          top: 0;
          right: 5px;
          font-weight: bold;
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          width: 23.5px;
          height: 23.5px;
          border-radius: 50%;
          background-color: #e7760e;
          color: #fff;
          vertical-align: middle;
          padding-top: 2px;
          display: inline-block;
        }
      }

      @include respond-below(xxl) {
        margin-right: 10px;
        .link-wrapper > a {
          width: 8.4rem;
        }
      }

      @include respond-below(xl) {
        margin-right: 0;
        height: 5rem;
        .link-wrapper > a {
          height: 5rem;
        }
      }

      @include respond-below(md) {
        display: none;
      }
    }

    // .block-quick-order {
    // span#quick-order-cart-number {
    //   right: 15px;
    // }
    // .link-wrapper > a {
    //   width: 12.5rem;
    // }
    // }
    /*
    ======================================================
    Block: Mobile Search Trigger
    ======================================================
    */
    .header-search-trigger {
      display: none;
      margin: 0 20px;

      p {
        margin-bottom: 0;
      }

      @include respond-below(md) {
        display: block;
      }

      @include respond-below(sm) {
        margin-right: 7px;
        margin-left: 7px;
      }
      .search-trigger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 10px;
        line-height: 130.19%;
        text-decoration: none;
        text-align: center;
        color: #161616;
        height: 4rem;
        cursor: pointer;
      }
    }

    /*
    ======================================================
    Block: Phone - small screen
    ======================================================
    */
    .header-phone--sm-screen {
      display: none;
      color: #fff;
      background-color: #24518a;
      font-weight: 700;
      font-size: 18px;
      line-height: 130.19%;
      padding: 10px;
      text-align: center;
      position: absolute;
      bottom: -4.5rem;
      height: 4.5rem;

      a.header__phone {
        color: #fff;
        margin: 0 auto;
        width: 160px;
        justify-content: center;

        &::before {
          background-image: url(../../images/icon-phone-white.svg);
        }
      }

      @include respond-below(lg) {
        display: block;
        left: -20px;
        right: -20px;
      }

      @include respond-below(sm) {
        left: -10px;
        right: -10px;
      }
    }
  }
}
