.node-product {
  // FULL VIEW

  // END FULL VIEW

  .crossout-price {
    text-decoration: line-through;
    color: $grey-3;
  }

  .field-name-koda-stock-field {
    .koda-product-stock {
      color: $uc-product-stock;
    }
  }
}

//view-mode-full

.node-product:not(.node-teaser) {
  > .group-content-section {
    .product-image {
      @include wrapper(relative, 100%);

      .main-product-image {
        text-align: center;

        @include respond-between(xs, md) {
          padding-left: 15px;
          padding-right: 15px;
        }

        a {
          @include wrapper(relative, 100%);
          img {
            margin: 0 auto;
            max-width: calc(100% - 30px);
          }
        }
      }

      .more-product-images {
        @include wrapper(relative, 100%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        align-content: center;

        padding-top: 15px;
        padding-bottom: 15px;

        @include respond-between(xs, md) {
          padding-left: 15px;
          padding-right: 15px;
        }

        a {
          border: 2px solid $grey-2;
          display: flex;
          flex: wrap;
          align-items: stretch;
          align-content: center;

          &:not(:first-child) {
            margin-left: 8px;
          }

          &:hover {
            border-color: $orange-2;
          }

          img {
            display: flex;
            height: auto;
          }
        }
      }
    }

    .group-description-section {
      margin-bottom: 20px;
      color: $grey-8;

      h3 {
        background-color: $product-header-highlight-color;
        font-size: 14px;
        font-weight: bold;
        padding: 12px 16px;
      }

      // > div {
      //   display: list-item;
      //   margin-left: 25px;
      // }

      .product-info {
        margin-bottom: 8px;
      }
    }
  }

  .horizontal-tabs-panes > .panel > .panel-body {
    display: block !important;
    opacity: 1 !important;
  }

  .hide-stock-price-blocks ~ .group-content-top-section .display-price {
    display: none;
  }

  .hide-stock-price-blocks ~ .add-to-cart {
    display: none;
  }

  .hide-stock-price-blocks ~ .field-name-product-enquiry-link {
    margin-top: 20px;
  }
}

.page-node.node-type-product {
  .page_header {
    border-bottom: none;
    margin-bottom: 0;

    .page-header {
      display: none;
    }
  }
}
