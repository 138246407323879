@mixin fa-icon($icon:"\f00d", $clr:$orange-2, $fsize: inherit, $fweight: inherit, $pos: relative, $fw:25px, $fh:25px ){

    &::before{
        display: inline;
        content: $icon;
        margin-right: 8px;
        color: $clr;
        font-family: $font-family-icon-fa;
        font-size: $fsize;
        font-weight: $fweight;
        @include wrapper($pos, $fw, $fh);
    }

}