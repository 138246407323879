.page-taxonomy.page-taxonomy-term,
.page-specials,
.page-products {
  .region-content-left {
    @include respond-above(lg) {
      width: 275px;
      margin-right: 50px;
      max-height: calc(100vh - 280px);
      position: sticky;
      top: 200px;
      overflow: auto;
    }
  }
  .view-four-column-tiles {
    .view-filters {
      background: #f5f5f5;
      padding: 10px 15px;
      margin-bottom: 25px;
      .views-exposed-widget.views-widget-filter-search_api_views_fulltext {
        display: none;
      }
      .views-exposed-widget {
        .form-item-sort-by {
          display: flex;
          align-items: center;
          width: 305px;
          .control-label {
            font-size: 15px;
            line-height: 1.5;
            letter-spacing: 0.02em;
            font-weight: 400;
            color: #000000;
            padding-right: 15px;
          }

          .form-select {
            width: 235px;
            height: 42px;
            border-radius: 0;
            padding-left: 10px;
          }
        }
      }
    }
    .view-content .views-infinite-scroll-content-wrapper {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      column-gap: 2.5rem;
      row-gap: 2rem;

      @include respond-below(xxl) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        // column-gap: 4rem;
        // row-gap: 4rem;
      }

      @include respond-below(xl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include respond-below(lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include respond-below(md) {
        margin-left: -10px;
        margin-right: -10px;
        column-gap: 0;
        row-gap: 0;
      }
      // @include respond-below(sm) {
      //   grid-template-columns: repeat(1, minmax(0, 1fr));
      //   justify-items: center;
      // }
    }
  }
}

.page-special .view-five-column-tiles {
  .view-content {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    column-gap: 4rem;
    row-gap: 4rem;

    @include respond-below(xxl) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      column-gap: 4rem;
      row-gap: 4rem;
    }

    @include respond-below(lg) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include respond-below(md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    // @include respond-below(sm) {
    //   grid-template-columns: repeat(1, minmax(0, 1fr));
    //   justify-items: center;
    // }
  }
}

#app-custom-subcategory-menu {
  @include respond-above(lg) {
    display: none;
  }

  > ul {
    border-radius: 3px;
    margin-bottom: 10px;
  }
  ul {
    list-style: none;
    padding: 10px 15px;
    background: #317ec4;
    font-weight: 500;
    font-size: 22px;
    line-height: 132.3%;
    color: #ffffff;
    a {
      color: inherit;
      text-decoration: none;
    }

    li {
      display: flex;

      a {
        flex: 1;
        text-align: right;
      }
    }
    > ul {
      display: none;
      padding-left: 3rem;
    }
    > li:last-child > .collapse-trigger {
      display: none;
    }
  }
  .collapse-trigger {
    cursor: pointer;
    color: #24518a;
    padding-right: 20px;
    &::after {
      content: '\f0da';
      font-family: 'FontAwesome';
      font-size: 20px;
      display: inline-block;
      line-height: 20px;
      font-weight: 700;
    }
    &.expanded::after {
      content: '\f0d7';
    }
  }
}
