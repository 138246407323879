.page--brands {
  .view-brands {
    .view-content {
      display: flex;
      flex-wrap: wrap;
      > .views-row {
        width: 25%;

        @include respond-between(md, lg) {
          width: 50%;
        }

        @include respond-below(md) {
          width: 100%;
        }
      }
    }
  }
}
