.page-products-categories {
  .view-categories-page {
    .view-content {
      display: flex;
      flex-wrap: wrap;
      > .views-row {
        width: 25%;
        padding: 2rem;

        @include respond-between(md, lg) {
          width: 50%;
        }

        @include respond-below(md) {
          width: 100%;
        }

        a {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 276px;
          img {
            max-height: 22rem;
            max-width: 100%;
            object-fit: contain;
            padding: 2rem;
          }

          h3 {
            font-size: 2.2rem;
            margin-top: auto;
          }
        }
      }
    }
  }
}