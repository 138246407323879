/*------------------------------------*\
  View Home Specials
\*------------------------------------*/
.view-home-specails-list {
    @include wrapper(relative, 100%);
    .view-content {
        width: 100%;
    }
    .view-footer {
        @include wrapper(relative, 100%);
        @include flex-container(row, wrap, center, center, center);
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 1px solid $grey-2;
        border-bottom: 1px solid $grey-2;

        .specials-more-btn {
            font-size: 18px;
            font-weight: bold;
            color: $black;

            &:active,
            &:hover {
                color: $primary-color;
                text-decoration: none;
            }

            &:before {
                content: "+";
                font-size: 22px;
                font-weight: normal;
                line-height: 22px;
                color: $primary-color;
                margin-right: 6px;
            }
        }
    }
}
