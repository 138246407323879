/** Bottom Banner **/
section.bottom-banner {
  background-color: $bottom-banner-color;
  color: $white;
}

.region-bottom-banner {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 30px 0;

  @include respond-below(md) {
    flex-direction: column;
    align-items: stretch;
    padding: 0 18px;
  }

  .block-mailchimp-signup {
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 40px;
    position: relative;
    letter-spacing: 0.02em;

    h2.block-title {
      width: 400px;
      margin-top: 0;
      padding-right: 20px;
      padding-bottom: 20px;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
    }

    @include respond-below(xl) {
      form > div .mailchimp-signup-subscribe-form-description,
      h2.block-title {
        width: 300px;
      }
      h2.block-title {
        padding-bottom: 45px;
      }
    }

    @include respond-below(lg) {
      flex-direction: column;
      align-items: flex-start;

      h2.block-title {
        padding-bottom: 0;
      }
    }

    @include respond-below(md) {
      align-items: center;
      text-align: center;
      margin-right: 0;
      margin-bottom: 20px;
    }

    form {
      flex: 1;
      width: 100%;
      > div {
        display: flex;
        align-items: center;

        @include respond-below(lg) {
          flex-wrap: wrap;
        }

        .form-group {
          margin-bottom: 0;
        }

        .mailchimp-signup-subscribe-form-description {
          position: absolute;
          left: 0;
          top: 40px;
          font-size: 18px;
          line-height: 22px;
          padding-right: 20px;
          @include respond-below(lg) {
            position: static;
            width: 100%;
            line-height: 32px;
            padding-bottom: 10px;
          }
        }

        .mailchimp-newsletter-mergefields {
          max-width: 692px;
          min-width: 320px;
          flex: 1;

          @include respond-below(sm) {
            min-width: unset;
          }
          .form-group {
            width: 100%;
          }

          input#edit-mergevars-email {
            width: 100%;
            height: 48px;
            border-radius: 90px;
            padding: 10px 40px;

            &::placeholder {
              text-transform: uppercase;
            }
          }
        }

        .btn.btn-default {
          @include btn(#3e3e3e);
          border-radius: 90px;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          text-transform: uppercase;
          height: 48px;
          margin-left: -60px;
        }
      }
    }
  }

  .bottom-call-us {
    color: #fff;

    @include respond-below(md) {
      text-align: center;
      margin-bottom: 20px;
    }

    > span {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.02em;
      line-height: 24px;
    }

    > a {
      font-size: 35px;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 42px;
      color: inherit;
      text-decoration: none;
    }
  }
}

/** Footer **/
footer.footer {
  background-color: $footer-color;
  padding-top: 20px;
}
.region-footer {
  color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 235px;

  @include respond-above(lg) {
    text-align: left;
  }

  @include respond-below(md) {
    padding-left: 20px;
    padding-right: 20px;
  }

  a {
    color: $white;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin-bottom: 8px;
  }

  h2.block-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin: 0 0 2rem;

    @include respond-below(md) {
      margin: 0 0 1rem;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    display: block;
    width: 100%;
    line-height: 3rem;
  }

  > section {
    padding-bottom: 0px;
    position: relative;

    .collapse-trigger {
      position: absolute;
      top: 0;
      left: 0;
      right: 20px;
      height: 20px;
      width: auto;
      cursor: pointer;
      text-align: right;
      display: none;

      &::after {
        content: "\f107";
        font-family: "FontAwesome";
        font-size: 20px;
        display: inline-block;
        line-height: 20px;
        font-weight: 700;
      }

      &.expanded::after {
        content: "\f106";
      }
    }
    @include respond-above(md) {
      .collapse-panel {
        display: block !important;
      }
    }

    @include respond-above(lg) {
      width: 18%;
      &.footer-block-secure-payment {
        width: 28%;
      }

      &.footer-block-location {
        width: 18%;
      }

      &.contact-in-footer {
        width: 18%;
      }
    }

    @include respond-below(lg) {
      width: 50%;
      padding-bottom: 20px;
    }
    @include respond-below(md) {
      width: 100%;
      .collapse-trigger {
        display: inline-block;
      }

      .collapse-panel {
        display: none;
      }
    }
  }

  .secure-payment-icons {
    width: 100%;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;

      &:last-child {
        align-items: flex-end;
        margin-top: 20px;
      }

      img {
        max-height: 50px;
      }

      @include respond-below(xl) {
        flex-direction: column;
        margin-bottom: 10px;
        align-items: flex-start;

        &:last-child {
          align-items: flex-start;
        }
      }
    }
  }

  .social-media-link {
    @include wrapper(relative, 40px, 40px);
    @include myanimated;
    display: inline-block;
    border: none;
    border-radius: 100%;
    margin-right: 4px;

    i {
      @include wrapper(relative, 100%, 40px);
      line-height: 38px;
      text-align: center;
      font-size: 22px;
      margin-top: -2px;
    }

    &.link-fackbook {
      background: linear-gradient(
        340deg,
        #091435 10.98%,
        #0e1a3e 24.07%,
        #1b2c56 45.42%,
        #30497e 72.2%,
        #3f5d9a 89.02%
      );

      i {
        margin-top: 0;
      }
    }

    &.link-linkedin {
      background: linear-gradient(
        340deg,
        #061d36 10.98%,
        #06233f 22.11%,
        #053457 40.25%,
        #044f7f 63.15%,
        #0374b4 89.02%
      );
    }

    &.link-instagram {
      background: linear-gradient(
        340deg,
        #20254c 10.98%,
        #29254d 15.44%,
        #41234f 22.7%,
        #692152 31.89%,
        #a01f57 42.5%,
        #da1c5c 52.6%,
        #dc255a 57.21%,
        #e13d56 64.74%,
        #ea654e 74.24%,
        #f69c44 85.24%,
        #fbb040 89.02%
      );
    }
  }
}
