.page-user-wish-list:not(.page-user-wish-list-) {
  #uc-wishlist-create-list-form {    
    margin-bottom: 20px;

    & ~ .table-responsive {
      td:not(:first-child) {
        text-align: center;
      }
    }
  }
}