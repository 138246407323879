.page-user-quick-order {
  .quick-order-popup-message,
  .quick-order-message-container {
    @extend .alert;
    @extend .alert-message;
    display: inline-block;
    width: 100%;

    &:empty {
      display: none;
    }
  }
  form#quick-order-form {
    margin-top: 40px;
    > div {
      display: flex;
      align-items: flex-start;

      .form-item-categories {
        width: 250px;
        margin: 0 20px;
        padding: 20px;
        background-color: #f5f5f5;
        font-weight: 400;
        font-size: 12px;
        line-height: 176.69%;
        color: #1a1a1a;
      }

      @include respond-below(lg) {
        flex-direction: column;

        .form-item-categories {
          margin-left: 0;
          margin-bottom: 20px;
        }
      }

      #checkboxes-div {
        flex: 1;
        max-width: 100%;

        @include respond-below(md) {
          .table-responsive {
            border: none;
          }
        }

        table {
          thead {
            tr {
              th {
                background: #474747;
                border: 1px solid #d4d4d4;
              }
            }
          }
          tr[class^='wish-list-category'] {
            background-color: transparent;

            td {
              color: #00adee;
              font-weight: 500;
              font-size: 18px;
              line-height: 127.69%;
              vertical-align: middle;
              &:first-child {
                padding-left: 0;
                padding-top: 30px;
              }
            }
          }
          tr[class^='wish-list-item'] {
            td {
              vertical-align: middle;
              text-align: center;
              border: 1px solid #d4d4d4;
              font-weight: normal;
              font-size: 14px;
              line-height: 1.5;
              text-align: center;
              color: #1a1a1a;

              &:first-child {
                min-width: 95px;
              }
            }

            &:nth-child(even) {
              background-color: #f1f8ff;
            }

            &:nth-child(odd) {
              background-color: #fff;
            }
          }
        }

        input.quick-order-quantity {
          width: 33px;
          height: 22px;
          padding: 0;
          text-align: center;
          -moz-appearance: textfield;
          background: #ffffff;
          border: 1px solid #dedede;
          box-sizing: border-box;
          border-radius: 0;
          vertical-align: middle;
        }

        input.quick-order-quantity::-webkit-outer-spin-button,
        input.quick-order-quantity::-webkit-inner-spin-button {
          margin: 0;
          -webkit-appearance: none;
        }

        .product-minus,
        .product-plus {
          border: 1px solid #dedede;
          box-sizing: border-box;
          border-radius: 0;
          background: #dedede;
          height: 22px;
          vertical-align: middle;
        }

        a.ctools-modal-remove-wishlist-item::before {
          color: #d70404;
        }
        .node-product {
          display: flex;
          .field-name-uc-product-image {
            margin-right: 10px;
            .field-items > .field-item:not(:first-child) {
              display: none;
            }
            img {
              max-height: 56px;
              min-width: 46px;
              width: auto;
              height: auto;
            }
          }
          .field-name-title {
            display: flex;
            align-items: center;
            h2 {
              font-weight: 400;
              font-size: 14px;
              line-height: 115.69%;
              color: #24518a;
              margin: 0;
            }
          }
        }

        a.ctools-modal-wishlist-to-checkout {
          @include btn($orange-3);
          display: inline-block;
          margin-bottom: 20px;
        }

        #cart-update-button {
          @include btn($menu-color);
          display: inline-block;
          margin-left: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }

  #uc-wishlist-remove-item-form {
    button.btn {
      margin-left: 10px;
    }
  }
}
