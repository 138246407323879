body.logged-in .my-account-block {
  .log-in {
    display: none
  }
}

body.not-logged-in .my-account-block {
  .my-account {
    display: none
  }
}

.my-account-block {
  text-align: right;


  a{
    font-size: 14px;
    margin: 0 10px;
  }
}