.node-home-slide-show {
  color: $white;
  position: relative;

  .field-name-field-image,
  .field-name-field-mobile-image {
    img {
      object-position: center center;
      width: 100%;
      min-height: 20rem;
      object-fit: cover;
    }
  }
  .field-name-field-mobile-image {
    display: none;
  }
  @include respond-below(md) {
    max-width: 100%;

    .field-name-field-image {
      display: none;
    }
    .field-name-field-mobile-image {
      display: block;
      img {
        height: 428px;
      }
    }
  }

  > .group-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    align-content: center;
    position: absolute;
    padding: 40px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .group-slider-options {
      display: flex;
      justify-content: flex-end;
      .btn {
        position: relative;
        width: 100%;
        display: block;
        border-radius: 0;
        text-transform: uppercase;
        font-size: 2rem;
        padding: 2rem 2.5rem;
        font-weight: bold;
        margin-bottom: 15px;
        @include respond-above(md) {
          width: auto;
          margin-bottom: 0;
          margin-right: 15px;
        }
      }
    }

    @include respond-above(md) {
      padding: 80px;
      h1,
      h2 {
        font-size: 55px;
      }
      .slider-content,
      .slider-content p {
        font-size: 22px;
        font-weight: 300;
      }
    }
  }
}
