#mmenu_left {
  padding-top: 0; // override
  background: $white;
  color: $grey-6;

  a {
    color: $grey-6;
  }

  .mm-header {
    display: none; // override
  }

  .cart-block-summary-items {
    text-shadow: none; // override
  }

  .mm-footer {
    background: $grey-6;
    border: 0;
  }

  > .mm-list {
    padding: 0; // override

    .mm-list:first-child {
      margin-top: 0; // override
    }
  }

  .mmenu-block-wrap {
    border: none;

    > span {
      padding: 0; //override

      > * {
        padding: 10px;
      }

      // :--------------------------------------------------------
      > .mmenu-mm-list-level-1.mm-list {
        background-color: #3d3d3d !important;
        li {
          border: unset;
          a {
            padding: 0.2em 0.5em !important;
            color: #fff;
            i {
              display: none;
            }
          }
        }
      }
      // :--------------------------------------------------------
    }
  }

  .mmenu-block-wrap:after {
    border-bottom-width: 0;
    content: '';
  }

  .mm-panel {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    &:after {
      display: none; //override
    }

    > .mm-list:nth-child(4) {
      background: $menu-color;
      flex-grow: 1;
      // overflow-y: auto;
    }
  }

  .my-account-block {
    background: $grey-6;

    a {
      color: $white;
    }
  }

  .mobile-mega-menu-block {
    background: $menu-color;

    .home-link {
      display: none;
    }
    .back-link {
      display: none;
    }
    .img-wrapper {
      display: none;
    }
    color: $white;
    a {
      color: $white;
    }
    .has-children > .react-menu-dropdown {
      padding-left: 15px;
    }

    .selected {
      background: rgb(25, 56, 96);
    }

    .parent-item ~ .this-items {
      padding-left: 15px;
    }

    .react-menu-item:not(:last-child):not(.selected) {
      border-bottom: 1px solid #406899;
    }
  }

  .item-name,
  .top-menu-link {
    padding: 4px 8px;
  }

  .top-banner-menu {
    background: $grey-6;
    > ul {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    li {
      border: none;
    }

    a {
      color: $white;
      padding: 2px 8px;
      text-align: left;
    }
  }

  .block-header-search {
    background: $white;
    padding-top: 0px !important;

    .views-exposed-form {
      width: 100%;
    }
  }

  .block-uc-cart {
    align-items: center;
    background: $white;
    color: $grey-6;
    flex-direction: column;

    a {
      color: $grey-6;
    }

    &:before {
      background: url('/sites/default/files/images/logo.png');
      content: '';
      width: 88%;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .cart-block-summary {
      width: auto;
      tbody {
        display: flex;
      }
    }

    .cart-block-summary-links {
      ul {
        display: flex;
        flex-direction: column;
      }
      li {
        display: flex;
        padding: 0;
      }
      a {
        padding: 0;
        text-decoration: none;
      }
    }

    .cart-block-checkout a {
      display: none;
    }
  }

  .top-menu-link {
    display: block;
  }
}

/*
  ======================================================
    KODAWEB2021
  ======================================================
*/

#mmenu_left .block-uc-cart {
  .cart-block-summary tbody > tr {
    display: flex;
  }
  .cart-block-summary-items {
    margin-right: 10px;
  }
  .cart-block-summary-total {
    margin-right: 10px;

    label {
      margin-right: 5px;
    }
  }
  .cart-block-summary-links ul {
    padding: 0;
    li {
      margin: 0 0 0 10px;
      border: none;

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      &::after {
        content: none;
        display: none;
      }
    }
  }
}
