.page-user-orders {
 .view-uc-orders-user {
   .table-striped {
     td {
       text-align: center;
     }

     .views-field-order-id {
      .uc-order-action img {
        display: inline;
        padding: 0;
        margin-left: 3px;
        top: 0;
      }
     }
   }
 } 
}