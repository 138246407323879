/*
======================================================
  Prodcut and Product Collection Teaser
======================================================
*/
.node-product.node-teaser,
.node-product-collection.view-mode-teaser {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 10px;
  position: relative;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  max-height: 600px;
  // max-width: 305px;
  overflow-y: auto;

  @include respond-above(md) {
    max-width: 305px;
  }

  /** Teaser Image **/
  .field-name-field-collection-image,
  .field-name-uc-product-image {
    width: 100%;
    height: 200px;
    transition: all 0.5s ease;

    // This hides additional images on the teaser blocks
    // They may instead want these images to be shown somehow
    .field-items > div:not(:first-child) {
      display: none;
    }

    div,
    a {
      width: 100%;
      height: 100%;
    }

    .product-image {
      float: none;
    }

    a {
      overflow: hidden;
    }

    img {
      // @include myanimated;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  /** Product Descritpion **/
  .group-description-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    .product-info {
      padding-top: 5px;
      color: $color-product-teaser-sku;
      font-weight: 400;
      text-align: center;
      @include respond-below(md) {
        font-size: 11px;
        text-align: left;
      }
    }
  }

  .field-name-title {
    margin-top: 20px;
    h2 {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      text-align: center;
      @include respond-below(md) {
        font-size: 13px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 40px;
      }
    }

    a {
      color: $color-product-teaser-title;
    }
  }

  .field-name-koda-stock-field {
    display: none;
  }

  .hide-stock-price-blocks {
    & ~ .display-price {
      display: none;
    }

    & ~ .add-to-cart {
      display: none;
    }

    & ~ .field-name-product-enquiry-link {
      margin-top: 20px;
      padding: 0 10%;
      width: 100%;
    }
  }
}

/*
======================================================
  Prodcut Teaser
======================================================
*/
.node-product.node-teaser {
  --hover_banner_height: 45px;
  --image_height: 200px;

  position: relative;

  @include respond-below(md) {
    --image_height: 128px;
  }

  .field-name-uc-product-image {
    height: var(--image_height);
  }

  /** Price Group **/
  .group-price-section {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    // align-items: flex-end;
  }

  .display-price {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    padding-left: 0;

    .uc-price {
      color: $red;
      padding-right: 3px;
    }

    .price-suffixes {
      font-weight: 400;
      font-size: 11px;
      margin-left: 3px;
    }
  }
  .field-name-price-breaking {
    .price-breaking {
      color: #fff;
      div {
        background-color: $btn-secondary-bg;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.5;
        padding: 6px 15px;
        border-radius: 3px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  /** Add to cart form **/
  .add-to-cart {
    form > div {
      display: flex;
      // flex-wrap: wrap;
    }
    .form-type-uc-quantity {
      align-items: center;
      display: flex;
      flex-direction: row;

      input[type='text'] {
        text-align: center;
        width: 38px;
        height: 43.5px;
        margin: 0 10px;
        border: 1.5px solid rgba(0, 0, 0, 0.3);
      }

      label {
        margin-bottom: 0;
        font-size: 0;

        &::before {
          content: 'Qty:';
          font-size: 15px;
          font-weight: 500;
        }

        .form-required {
          display: none;
        }
      }

      @include respond-below(md) {
        input[type='text'] {
          width: 22px;
          height: 27px;
          border-radius: 0;
          margin: 0 5px;
        }
        label::before {
          font-size: 9px;
        }
      }
    }

    .form-item-compare,
    .form-item-quick-order {
      position: absolute;
      top: calc(var(--image_height) + 10px - var(--hover_banner_height) + 13px);
      display: none;
      margin: 0;
      color: #fff;

      label.control-label {
        font-weight: 500;
        font-size: 15px;
        line-height: 1.2;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #ffffff;

        @include respond-below(xxl) {
          font-size: 15px;
        }
      }
    }
    .form-item-compare {
      right: 15px;
      label.control-label {
        @include custom-checkbox('default');
        @include respond-below(xl) {
          padding-left: 22px;
        }
      }
    }

    .form-item-quick-order {
      left: 15px;
      label.control-label {
        @include custom-checkbox('heart');
        @include respond-below(xxl) {
          padding-left: 22px;
        }
      }
    }

    .form-actions {
      padding: 0;
      margin: 0;
      flex: 1;
      button {
        @include btn($orange-3);
        width: 100%;
        padding: 1rem;
        .icon {
          display: none;
        }

        @include respond-below(md) {
          height: 2.7rem;
          font-size: 1.2rem;
        }
      }

      .uc-ajax-cart-alt-status-messages {
        .alert {
          animation: 1s fadeOut;
          animation-delay: 1s;
          animation-fill-mode: forwards;
          position: absolute;
          bottom: 60px;
          right: 0;
          padding: 1rem 1.5rem 1rem 1rem;
          z-index: 11;
          .close {
            position: absolute;
            right: 5px;
            top: 5px;
          }
          > strong {
            display: none;
          }
          @include respond-below(md) {
            bottom: 40px;
            font-size: 1.2rem;
          }
        }
      }
    }

    .panel-default {
      box-shadow: none;
      min-width: 130px;
      position: absolute;
      background: none;
      right: 0;
      top: -24px;
      width: calc(100% - 120px);
      .panel-body {
        z-index: 81;

        &.collapse {
          z-index: -10;
        }
      }
    }

    .add-to-cart-in-stock,
    .ship-today {
      display: none;
    }
    p {
      margin: 0;
    }
  }

  /** Teaser Hover Banner with Quick Order and Compare Checkbox **/
  &::after {
    content: '';
    position: absolute;
    top: calc(var(--image_height) + 10px - var(--hover_banner_height));
    left: 0;
    right: 0;
    height: var(--hover_banner_height);
    width: 100%;
    background-color: #24518a;
    display: inline-block;
    opacity: 0;
    transition: all 0.5s ease;
  }
  &:not(.product-add-disabled):hover {
    .field-name-uc-product-image {
      opacity: 0.3;
    }

    .add-to-cart {
      .form-item-compare,
      .form-item-quick-order {
        // display: inline-block;
        display: none; // Hide compare and quick order checkbox
        z-index: 10;
      }
    }

    // Do not display hover banner
    // &::after {
    //   opacity: 1;
    //   visibility: visible;
    // }
  }

  /** Product Promotion Tag on top **/
  .field-name-field-product-promotions {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;

    .Featured {
      background-color: $featured-color;
    }
    .Special {
      background-color: $special-color;
    }
    .Clearance {
      background-color: $clearance-color;
    }

    .Featured,
    .Special,
    .Clearance {
      padding: 4px 10px;
      border-radius: 0px;
      display: block;
      margin-bottom: 8px;
      color: $white;
      text-transform: capitalize;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }
  }

  /** Notify In Stock **/
  .field-name-product-enquiry-link,
  .field-name-notify-in-stock {
    // margin-top: -26px;
    .field-item {
      display: flex;
      flex-direction: column-reverse;
      color: $red;
      font-weight: 500;
    }

    a {
      @include btn($orange-3);
      width: 100%;
    }
  }

  .field-name-product-enquiry-link-submitted,
  .field-name-notify-in-stock-submitted {
    margin-top: -5px;
    color: $red;

    p {
      font-weight: 500;
      @include respond-below(md) {
        font-size: 1.2rem;
      }
    }
  }
}
/*
======================================================
  Prodcut Collection Teaser
======================================================
*/
.node-product-collection.view-mode-teaser {
  /** Product Collection view-node-teaser Button **/
  .field-name-node-link {
    a.btn {
      @include btn($orange-3);
      width: 100%;
    }
  }

  .field-name-title {
    min-height: 85px;
  }
}

/*
======================================================
  Prodcut Compare Page Teaser
======================================================
*/
.page-product-comparison .node-product.node-teaser {
  &:hover {
    .field-name-uc-product-image {
      opacity: 1;
    }

    .add-to-cart {
      .form-item-compare,
      .form-item-quick-order {
        display: none;
      }
    }

    &::after {
      visibility: hidden;
    }
  }

  .product-info.model {
    display: none;
  }

  .group-price-section {
    min-height: unset;
    margin-top: 0;
    .display-price {
      width: 100%;
      justify-content: center;
      text-transform: none;
    }
  }

  .add-to-cart {
    .form-type-uc-quantity {
      display: none;
    }
  }
}

/*
======================================================
  Six Column Carousel Teaser
======================================================
*/
.slick--optionset--six-column-carousel .node-product-collection.node-teaser,
.slick--optionset--six-column-carousel .node-product.node-teaser {
  --hover_banner_height: 45px;
  --image_height: 125px;

  max-width: 253px;
  max-height: 600px;

  .field-name-field-collection-image {
    height: var(--image_height);
  }

  .group-description-section {
    min-height: 56px;
    .field-name-title {
      margin-top: 10px;
      font-weight: 500;
      line-height: 128.69%;
      letter-spacing: 0.02em;

      h2 {
        font-size: 14px;
      }
    }

    .product-info.model {
      font-size: 12px;
      // color: #b1b1b1;
    }
  }
  .add-to-cart {
    .form-item-compare,
    .form-item-quick-order {
      label.control-label {
        font-size: 13px;
        line-height: 18px;
        padding-left: 22px;
      }
    }

    .form-item-compare {
      right: 10px;
    }

    .form-item-quick-order {
      left: 10px;
    }

    .form-type-uc-quantity input[type='text'] {
      height: 34px;
    }

    .form-actions button {
      height: 34px;
    }
  }

  /** Notify In Stock **/
  .field-name-product-enquiry-link,
  .field-name-notify-in-stock {
    margin-top: -16px;
    a {
      height: 34px;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
      padding: 10px;
    }
  }
}
