/*------------------------------------*\
  File: Testimonials
\*------------------------------------*/
.page-testimonials {
  .views-widget-filter-field_testimonial_category_tid {
    width: 100%;

    #edit-category {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond-below(sm) {
        flex-direction: column;
      }

      > .radio {
        margin: 0;
        border-bottom: 1px solid #f0f0f0;
        label.control-label {
          position: relative;
          padding: 5px 10px 13px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #3f3f3f;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            margin: 0;
          }

          &.selected::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 8px;
            bottom: 0;
            left: 0;
            display: inline-block;
            background-color: $orange-2;
          }
        }
      }
    }
  }
  .view-content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 5rem;
    column-gap: 4.5rem;

    @include respond-below(xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 3rem;
    }

    @include respond-below(md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include respond-below(sm) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .node-testimonials.node-teaser {
      border: 1px solid #d0d0d0;
      box-sizing: border-box;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    }
  }
}

/*
======================================================
  Node: Testimonials
======================================================
*/
.node-type-testimonials {
  // Testimonial Node Title
  section.page_header {
    border: none;
  }

  .testimonial__page {
    max-width: 1472px;
    padding: 0 30px;
    margin: 0 auto;

    // Testimonial Node Slick Carousel
    .slick {
      .media--image {
        width: 100%;
        img {
          width: 100%;
          min-height: 50rem;
          object-fit: cover;

          @include respond-below(lg) {
            min-height: 20rem;
          }
        }
      }
    }

    // Testimonial Node Content
    &__content {
      display: flex;
      margin-top: 5rem;
      letter-spacing: 0.5px;

      @include respond-below(lg) {
        flex-direction: column;
      }

      .field-name-body {
        flex: 1;

        .label-above {
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 1.5;
          color: #343434;
          padding-bottom: 2rem;
          margin-bottom: 2rem;
          border-bottom: 1px dashed #dadada;
        }

        p {
          font-size: 16px;
          line-height: 1.6;
          color: #464646;
        }
      }
    }

    &__details {
      width: 46rem;
      margin-left: 8.5rem;

      @include respond-below(xl) {
        margin-left: 3rem;
      }

      @include respond-below(lg) {
        margin-left: 0;
        width: 100%;
      }

      > div:first-child {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1.5;
        color: #343434;
        padding-bottom: 2rem;
        border-bottom: 1px dashed #dadada;
      }

      > .field {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.5;
        color: #353637;
        padding: 1rem 0;

        .field-label {
          width: 15rem;
          font-weight: 700;
          color: #313131;
        }
        border-bottom: 1px dashed #dadada;
      }
    }
  }
}
