.block-header-search {
  .views-exposed-form {
    position: relative;
    max-width: 64.5rem;

    @include respond-below(lg) {
      max-width: 100%;
    }

    .views-exposed-widget {
      padding: 0;
    }

    .views-exposed-widgets {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0;

      .views-widget-filter-search_api_views_fulltext {
        @include wrapper(relative, 100%, 100%); // pos w h
        margin: 0;
        height: 5.5rem;
        // @include respond-below(lg) {
        //   height: 3rem;
        // }
      }

      .input-group {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding-right: 6.2rem;

        .input-group-addon {
          display: none;
        }
      }

      .form-control {
        margin: 0;

        &.form-text {
          box-shadow: none;
          font-size: 14px;
          color: $menu-color;
          padding: 8px 15px;
          border: 2px solid $search-border-color;
          font-family: $font-family-Roboto;
          font-weight: 400;
          overflow: hidden;
          height: 5.5rem;

          @media all and (min-width: 1440px) {
            font-size: 18px;
          }

          // @include respond-below(lg) {
          //   height: 3rem;
          // }

          &::-webkit-input-placeholder {
            /* Edge */
            color: $search-input-color;
            font-weight: 300;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $search-input-color;
            font-weight: 300;
          }

          &::placeholder {
            color: $search-input-color;
            font-weight: 300;
          }
        }
      }

      .views-submit-button {
        @include wrapper(absolute, auto, 100%);
        right: 0;
        top: 0;
        padding: 0;
        margin: 0;
        z-index: 10;

        .form-submit {
          @include wrapper(relative, 6.4rem, 100%);
          margin: 0;
          color: transparent;
          border-left-style: none !important;
          border-top-left-radius: 0 0;
          border-bottom-left-radius: 0 0;
          border: 2px solid $search-border-color;
          background-color: $white;
          outline: none;
          background: $menu-color;
          border-radius: 0px 4px 4px 0px;

          &::before {
            position: absolute;
            content: '\f002';
            width: 50px;
            height: auto;
            left: 6px;
            top: 15px;
            color: #fff;
            font-size: 25px;
            line-height: 20px;
            text-align: center;
            font-family: $font-family-icon-fa;
          }

          i {
            color: $menu-color;
            font-size: 25px;
          }

          // @include respond-below(lg) {
          //   &::before {
          //     font-size: 16px;
          //     line-height: 16px;
          //     top: 4px;
          //   }
          // }
        }
      }
    }
  }
}
