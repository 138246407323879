@mixin custom-checkbox($type) {
  /* Customize the label (the container) */
  display: block;
  position: relative;
  padding-left: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: unset;
  cursor: pointer;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 7px;
    top: 2px;
    width: 5px;
    height: 13px;
    border: solid #00adee;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    // background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    // background-color: #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  @if $type == 'heart' {
    .checkmark {
      background-color: transparent;
      &::before {
        content: '\f004';
        font-family: $font-family-icon-fa;
        height: 18px;
        width: 18px;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 18px;
        line-height: 18px;
      }

      &::after {
        display: none;
      }
    }
    input:checked ~ .checkmark:before {
      color: #00adee;
    }
    input:checked ~ .checkmark:after {
      display: none;
    }
  }

  @if ($type == 'box') {
    .checkmark {
      background-color: transparent;

      &::after {
        display: none;
      }
      &:before {
        content: '';
        background-image: url('../../images/icon-box-white.png');
        background-size: contain;
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    input:checked ~ .checkmark:before {
      background-image: url('../../images/icon-quick-order.png');
    }
    input:checked ~ .checkmark:after {
      display: none;
    }
  }

  @if ($type == 'label-change') {
    font-size: 0;
    padding-left: 0;
    .checkmark {
      font-size: 16px;
      display: inline-block;
      width: auto;
      position: relative;

      &::after {
        position: relative;
        display: inline-block;
        transform: none;
        border: none;
        width: auto;
        height: auto;
      }
    }
  }
}
