.page-node.node-type-product .field-name-field-bundle-products {
  position: relative;
  .collapse-trigger {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    width: auto;
    cursor: pointer;
    text-align: right;
    font-size: 0;
    z-index: 1;
    display: none;

    @include respond-below(md) {
      display: block;
    }
    &::after {
      content: '\f107';
      font-family: 'FontAwesome';
      font-size: 27px;
      display: inline-block;
      line-height: 30px;
      padding-top: 15px;
      font-weight: 700;
      padding-right: 20px;
      color: #fff;
    }

    &.expanded::after {
      content: '\f106';
    }
  }
  > .field-label {
    font-weight: 700;
    font-size: 22px;
    line-height: 132.3%;
    color: #3f3f3f;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 3px solid #e5e5e5;

    @include respond-below(md) {
      background: #24518a;
      border-radius: 3.62px;
      color: #fff;
      border-bottom: none;
      padding: 15px;
    }
  }

  > .field-items {
    @include respond-above(md) {
      display: block !important;
    }
  }
  form#ubercart-bundle-products-form > div {
    display: inline-block;
    width: 100%;

    @include respond-below(lg) {
      display: flex;
      flex-direction: column;
    }

    #edit-product-display-group {
      display: inline-block;
      width: 70%;
      float: left;

      @include respond-below(lg) {
        width: 100%;
      }
    }

    #edit-bundle-products-multi-selection,
    .bundle_products_multi_selection_base {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 70%;
      float: left;
      max-height: 270px;

      @include respond-below(lg) {
        // display: none;
        width: 100%;
        background-color: #f5f5f5;
        padding: 0rem 1.5rem 1rem;
        order: 1;

        .checkbox {
          margin: 0;
          padding: 1rem 0;
          border-bottom: 1px dashed #dbdbdb;
        }
      }
    }

    #edit-bundle-product-total {
      display: inline-block;
      width: 30%;

      > .panel-body {
        background-color: #f5f5f5;
        padding: 30px 34px;
        display: flex;
        flex-direction: column;
        width: 335px;
        color: #3f3f3f;

        .ubercart_bundle_products_total {
          display: flex;
          font-weight: 500;
          font-size: 16px;
          line-height: 132.3%;
          margin: 10px 0;

          .ubercart_bundle_products_total_digit {
            font-size: 28px;
            color: #d70404;
            margin-left: 5px;

            .bundle-product-total-gst {
              font-size: 13px;
              font-weight: 400;
            }
          }
        }

        .ubercart_bundle_products_saved_total {
          font-size: 14px;
          font-style: italic;
        }

        .btn.btn-success {
          height: 60px;
          border-radius: 3.5px;
          font-size: 25px;
          line-height: 1.5;
          margin-top: 10px;
          span.icon {
            display: none;
          }
        }
      }

      @include respond-below(lg) {
        width: 100%;
        margin-bottom: 0;
        > .panel-body {
          width: 100%;
          align-items: center;
          text-align: center;
          padding: 15px 0 20px;
          .btn.btn-success {
            width: 100%;
          }
        }

        .btn.btn-success {
          height: 48px;
        }
      }
    }

    .bundle_products_multi_selection_base {
      font-weight: 700;
    }

    #edit-product-display-group > .panel-body {
      display: flex;
      flex-wrap: wrap;

      .ubercart_base_bundle_product,
      .bundle-product-item-wrapper {
        position: relative;
        margin: 0px 0;
      }

      .ubercart_bundle_products {
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 0;
        z-index: 1;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        color: #fff;
        background-color: #009cde;
        text-align: center;
        cursor: pointer;

        &::before {
          content: '\00D7';
          font-size: 18px;
          line-height: 21px;
          font-weight: 500;
        }
      }

      .bundle-product-item {
        &:not(.js-hide) {
          display: flex;
          align-items: center;
        }
        &::before {
          content: '+';
          margin: 0 9px;
          font-size: 20px;
          color: #e87409;
        }
      }
      .node-product.view-mode-bundle_product_teaser {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .field-name-uc-product-image {
          border: 1.16766px solid #d9d9d9;
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .field-name-title {
          display: none;

          h5 a,
          h5 a:hover {
            font-size: 14px;
            line-height: 135.5%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #3f3f3f;
          }
        }
      }

      @include respond-below(md) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 0;
        row-gap: 0.5rem;
        padding-bottom: 0;

        .ubercart_base_bundle_product {
          padding-left: 12px;

          h5 {
            font-weight: 700;
          }
        }

        .bundle-product-item-wrapper:nth-child(4n + 1) {
          .bundle-product-item::before {
            content: ' ';
          }
        }

        &::before {
          content: none;
        }
        .ubercart_bundle_products {
          position: absolute;
          left: calc(50% + 45px);
        }
        .bundle-product-item:not(.js-hide) {
          min-width: 100px;
          // display: block;
          align-items: flex-start;
          &::before {
            // content: none;
            padding-top: 35px;
            min-width: 12px;
            margin: 0;
          }
        }
        .node-product.view-mode-bundle_product_teaser {
          padding: 0 1rem;
          .field-name-title {
            display: block;
          }
        }
      }
    }
  }
}
