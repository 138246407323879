//Fix loading issue
.slick__slider > .slick__slide {
    display: none;
}

.slick--skin--boxed-carousel {
    .slide__content,
    .slide__content:hover {
        border: none;
        border-radius: 0;
        transition: none;
        box-shadow: none;
    }
}

// Default
.slick {
    position: relative;
    .slick-slide {
        height: auto;
    }
    .slick__arrow {
        .slick-prev,
        .slick-next {
            font-size: 0;
            width: 50px;
            height: 83px;
            border: 0;
            position: absolute;
            padding: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .slick-prev {
            background: url('../../images/icon-prev.svg') center center no-repeat;
            left: -70px;
            &:hover {
                opacity: 0.5;
            }
        }
        .slick-next {
            background: url('../../images/icon-next.svg') center center no-repeat;
            right: -70px;
            &:hover {
                opacity: 0.5;
            }
        }
    }
}

.slick-dots {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &::before {
                content: '';
                color: transparent;
                border: 1px solid rgb(155, 155, 155);
                border-radius: 50%;
                width: 16px;
                height: 16px;
                opacity: 0.5;
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
        &.slick-active button:before {
            background-color: #1c1c1c;
            border-color: #1c1c1c;
            color: transparent;
            opacity: 0.75;
        }
    }
}

// Homepage Main Slider Arrow
.view-home-slide-show {
    min-height: 250px;
    .slick__arrow {
        display: none;
        .slick-prev {
            &:hover {
                background: url('../images/icon-prev-hover2.svg') center center no-repeat;
            }
        }
        .slick-next {
            &:hover {
                background: url('../images/icon-next-hover2.svg') center center no-repeat;
            }
        }

        .slick-prev,
        .slick-next {
            width: 34px;
            height: 34px;

            @include respond-above(sm) {
                width: 60px;
                height: 60px;
                background-size: 60px 60px;
                &:hover {
                    background-size: 60px 60px;
                }
            }
        }
    }

    .slide__content {
        padding: 2.5rem 1rem;
    }

    @include respond-below(md) {
        .slide__content {
            padding: 0;
        }
    }
}

// Homepage Brands
// .view-homepage-brands {
//     .slide__grid {
//         @include respond-below(xs) {
//             &:not(:first-child) {
//                 margin-top: 15px;
//             }
//             img {
//                 margin-bottom: 15px;
//             }
//         }
//     }

//     .slick__arrow {
//         .slick-prev {
//             &:hover {
//                 background: url('../images/icon-prev-hover2.svg') center center no-repeat;
//             }
//         }
//         .slick-next {
//             &:hover {
//                 background: url('../images/icon-next-hover2.svg') center center no-repeat;
//             }
//         }

//         .slick-prev,
//         .slick-next {
//             width: 34px;
//             height: 34px;
//         }
//     }
// }

// Homepage Brands
// Products Carousel
.slick--view--homepage-brands.slick,
.products-carousel.slick {
    > .slick__slide {
        max-width: Max(320px, 20%);
    }
    .slick-slider {
    }
    .slide__content {
        padding: 0 20px;
        display: flex;
        justify-content: center;
    }

    @include respond-below(md) {
        margin-left: -10px;
        margin-right: -10px;
        .slide__content {
            padding: 0;
            .node-product.node-teaser {
                border-color: #e8e8e8;
                box-shadow: none;
            }
        }
    }

    .slick__arrow {
        .slick-prev,
        .slick-next {
            width: 30px;
            height: 50px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .slick-prev {
            left: -35px;
        }
        .slick-next {
            right: -35px;
        }

        // FIX slick arrows responsive display
        // Always show arrows container
        // Arrow buttons will be hidden when elements number less than a row
        // &.element-hidden {
        //     display: block;
        // }

        @include respond-below(xl) {
            .slick-prev {
                left: -20px;
            }
            .slick-next {
                right: -20px;
            }
        }

        @include respond-below(lg) {
            .slick-prev {
                left: -15px;
            }
            .slick-next {
                right: -15px;
            }
        }

        // @include respond-below(md) {
        //     display: none;
        // .slick-prev,
        // .slick-next {
        //     width: 22px;
        //     height: 36px;
        // }
        // .slick-prev {
        //     left: -3px;
        // }
        // .slick-next {
        //     right: -3px;
        // }
        // }

        // @include respond-below(sm) {
        //     .slick-prev {
        //         left: 3px;
        //     }
        //     .slick-next {
        //         right: 3px;
        //     }
        // }
    }

    .slick-dots {
        margin-bottom: 2rem;
    }
}

.products-carousel.slick {
    .slick__arrow.element-hidden {
        @include respond-above(lg) {
            display: block;
        }
    }
    .slick__arrow {
        @include respond-below(lg) {
            display: none;
        }
    }
}

// Product Images Carousel
.slider-product-image {
    max-width: 664px;
    width: 100%;
    margin: 0 auto;
    padding: 0 55px;

    img {
        width: 100%;
        height: 100%;
    }

    .slick__arrow {
        .slick-prev,
        .slick-next {
            width: 30px;
            height: 50px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .slick-prev {
            left: 10px;
        }
        .slick-next {
            right: 10px;
        }
    }
}

// Testimonial Image Carousel
.slick--optionset--testimonial-slider {
    .slick__arrow {
        .slick-prev,
        .slick-next {
            width: 30px;
            height: 50px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .slick-prev {
            left: -50px;
        }
        .slick-next {
            right: -50px;
        }

        @include respond-below(xl) {
            .slick-prev {
                left: -40px;
            }
            .slick-next {
                right: -40px;
            }
        }
    }
}
