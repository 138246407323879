#app-custom-menu-2 {
  .home-link {
    display: none;
  }

  .back-link {
    display: none;
  }

  .img-wrapper {
    display: none;
  }

  .has-children > .react-menu-dropdown {
    padding-left: 15px;
  }

  a {
    color: $white;
  }
  .selected {
    background: rgb(25, 56, 96);
  }

  .parent-item ~ .this-items {
    padding-left: 15px;
  }

  .react-menu-item:not(:last-child):not(.selected) {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  }

  .react-menu-option,
  .react-menu-item {
    padding: 13px 14px;
    font-size: 22px;
    line-height: 100%;
    font-weight: 500;
  }
}

.mobile-secondary-menu {
}
