// Hide Header cart list
.cart-block-items.collapsed {
  display: none;
}
.block-uc-ajax-cart-alt {
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // white-space: nowrap;
  // flex-wrap: wrap;

  position: relative;

  .uc-ajax-cart-alt-cart {
    display: none;
  }
  .block-title {
    margin: 0;
    font-size: 0;
  }

  h2 {
    margin: 0;

    a {
      display: flex;
      flex-direction: column;
      color: #292929;
      text-decoration: none;
      align-items: center;
      justify-content: space-between;
      width: 9.4rem;
      height: 5.5rem;

      @include respond-below(xl) {
        height: 5rem;
      }

      @include respond-below(md) {
        width: 4.5rem;
        height: 40px;

        align-items: stretch;
      }
      &::after {
        content: 'Shopping Cart';
        width: 100%;
        color: #292929;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;

        @include respond-below(md) {
          content: 'Cart';
          font-weight: 500;
          font-size: 10px;
          line-height: 130.19%;
          text-align: center;
        }
      }
    }
    .cart-block-icon-empty,
    .cart-block-icon-full {
      background-image: url('/sites/all/themes/kodaweb2021/images/icon-cart.svg');
      width: 28px;
      height: 24px;
      background-size: auto 24px;
      background-repeat: no-repeat;
      margin-right: 2px;
    }
  }

  .cart-block-title-bar {
    display: none;
  }

  .cart-block-summary-items {
    font-weight: bold;
    font-size: 0;
    text-align: center;
    width: 23.5px;
    height: 23.5px;
    border-radius: 50%;
    background-color: #e7760e;
    color: #fff;
    vertical-align: middle;
    padding-top: 2px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 65px;
    z-index: -1;

    > span {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .cart-block-summary-total {
    display: none;
  }

  .cart-block-summary-links {
    display: none;
  }

  @include respond-below(md) {
    flex-wrap: nowrap;
    padding-top: 0;
    h2 {
      .cart-block-icon-empty,
      .cart-block-icon-full {
        width: 26px;
        height: 24px;
        background-size: 26px auto;
        margin-right: 0;
      }
    }

    .cart-block-summary-items {
      width: 20px;
      height: 20px;
      top: 0;
      left: 25px;
      > span {
        font-size: 11px;
        line-height: 17px;
      }
    }
  }
}
