.container {
  width: 100%;
  padding-right: 3%;
  padding-left: 3%;
  display: inline-block;

  @include respond-below(lg) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @include respond-below(md) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

div.horizontal-tabs {
  border: none;

  ul.horizontal-tabs-list {
    background: none;
    border: none;

    li {
      border: none;
      background: none;

      &:hover {
        background: none;
      }

      a:hover {
        background: none;
      }
    }
  }
}

.panel {
  border: none;
}

.panel-default {
  .panel-heading {
    background: none;
    border: none;
  }

  .panel-title {
    border-left: 6px solid $primary-color;
    font-weight: bold;
    padding: 5px 14px;
    text-transform: uppercase;
  }
}

.table > tbody > tr,
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: $grey-2;
}

.table {
  > tbody > td {
    text-align: center;
  }

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border-top: none;
    white-space: normal;
  }

  > thead > tr > th {
    background-color: $grey-3;
    border-bottom: none;
    color: $white;
    text-align: center;
    a,
    a:hover,
    a:visited {
      color: $white;
    }

    &.active {
      color: $grey-3;
      a,
      a:hover,
      a:visited {
        color: $grey-3;
      }
    }
  }
}

.table-responsive > .table > tbody > tr > td {
  white-space: normal;
}

.form-control {
  padding: 0;
}
