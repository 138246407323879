.top-banner-menu {
  @include flex-container(column, wrap, flex-end, center, center);
  @include respond-above(sm) {
    flex-direction: row;
    text-align: right;
    flex-grow: 1;
  }

  ul {
    @include flex-container(column, wrap);
    @include respond-above(sm) {
      flex-direction: row;
    }

    > li {
      list-style: none;
      > a {
        padding: 0 8px;
        font-size: 14px;

        &:focus,
        &:visited,
        &:active {
          background-color: transparent;
        }

        &:hover {
          background-color: transparent;
          color: $primary-color;
        }
      }
    }
  }
}

#mmenu_left .top-banner-menu {
  position: relative;
  width: 100%;
  ul.mm-list {
    position: relative;
    width: 100%;
    li {
      position: relative;
      width: 100%;
    }
  }
}
