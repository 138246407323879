.page--cart.page--checkout {
  section.page_header {
    margin-bottom: 0;
    .easy-breadcrumb {
      margin-bottom: 0;
    }
    h1.page-header {
      margin: 1rem 0;
    }
  }
  .checkout__bottom-msg {
    font-size: 16px;
    line-height: 151.69%;
    color: #969696;
    padding-top: 6rem;
    @include respond-above(lg) {
      width: 66.66%;
    }
  }
  /*
    ======================================================
     uc-cart-checkout-form
    ======================================================
  */
  form.uc-cart-checkout-form {
    > div {
      display: inline-block;
      width: auto;
    }

    .form-actions,
    .panel-default.form-wrapper {
      width: 50%;
      padding: 0 20px;
      display: inline-block;
      float: left;
    }

    @include respond-below(md) {
      .form-actions,
      .panel-default.form-wrapper {
        width: 100%;
      }
    }

    .panel-default.form-wrapper {
      /*
      ======================================================
       Payment Pane
      ======================================================
      */
      &#payment-pane {
        float: none;
        margin-bottom: 0;
        .panel-body {
          display: flex;
          flex-direction: column;
        }

        #line-items-div {
          border: none;
          float: unset;
          order: 2;
          margin: 0;
          padding: 0 0 20px;
        }

        #payment-details {
          order: 1;
        }

        .form-item-panes-payment-payment-method {
          .form-item {
            width: 100%;
            border-top: 1px solid #f5f5f5;
            padding: 20px 0;
            label.control-label {
              width: 100%;
              display: flex;
              flex-wrap: wrap;

              &[for^='edit-panes-payment-payment-method-uc-dps-pxpay-dps'] {
                &::after {
                  content: 'When you click the Place Order button below you will be directed to the Windcave payment gateway to make your payment.';
                  font-size: 13px;
                  width: 100%;
                }
              }

              &[for^='edit-panes-payment-payment-method-afterpay'] {
                .payment-method-option-info::after {
                  content: 'You will be redirected to the Afterpay website when you click the Place order button';
                  font-size: 13px;
                }
              }

              &[for^='edit-panes-payment-payment-method-zippay'] {
                .payment-method-option-info::after {
                  content: 'You will be redirected to the Zip website when you click the Place order button';
                  font-size: 13px;
                }
              }

              &[for^='edit-panes-payment-payment-method-laybuy'] {
                .payment-method-option-info::after {
                  content: 'You will be redirected to the Laybuy website when you click the Place order button';
                  font-size: 13px;
                }
              }

              #pxpay-includes {
                margin-left: auto;

                > a {
                  display: inline-block;
                  margin-bottom: 5px;
                }
              }

              .payment-method-option-info {
                display: flex;
                flex-direction: column;
                > p,
                > a {
                  font-size: 13px;
                  display: inline;
                  margin-bottom: 0.5rem;
                }

                > a {
                  &::after {
                    content: '\f08e';
                    font-family: $font-family-icon-fa;
                    padding-left: 3px;
                  }
                }

                > p.afterpay-help-information,
                > p.laybuy-payment,
                > p.zippay-help-information {
                  font-size: 15px;
                  display: inline-block;
                  width: 100%;
                }

                .zippay-checkout-logo,
                .laybuy-checkout-logo,
                .afterpay-checkout-logo {
                  float: right;
                  display: inline-block;
                  width: 120px;
                  height: 25px;
                  background-repeat: no-repeat;
                  background-position: center;
                }

                .afterpay-checkout-logo {
                  background-image: url(../../images/logo-afterpay.png);
                }

                .laybuy-checkout-logo {
                  background-image: url(../../images/logo-laybuy.svg);
                  background-size: auto 22px;
                }

                .zippay-checkout-logo {
                  background-image: url(../../images/logo-zip.png);
                }
              }
            }
          }
        }
      }
    }
  }

  /** New Grid Layout **/
  #uc-cart-checkout-form {
    > div {
      display: flex;
      flex-direction: column;
      > * {
        margin: 0;
        width: 100%;
      }
    }

    .panel-body {
      padding-top: 0;
    }
    @include respond-above(lg) {
      &.medium-order-summary > div {
        grid-template-rows:
          155px minmax(min-content, 50px) minmax(min-content, 120px) min-content min-content
          min-content;
        grid-template-areas:
          'order-review customer-info shipping'
          'order-review delivery-info shipping'
          'order-review delivery-info comments'
          'order-review delivery-info payment'
          'coupon-code delivery-info payment'
          'coupon-code . actions';
      }
      &.short-order-summary > div {
        grid-template-rows:
          155px minmax(min-content, 50px) minmax(min-content, 150px) min-content min-content
          minmax(min-content, 40px) min-content;
        grid-template-areas:
          'order-review customer-info shipping'
          'order-review delivery-info shipping'
          'order-review delivery-info comments'
          'coupon-code delivery-info payment'
          'coupon-code delivery-info payment'
          'coupon-code . payment'
          '. . actions';
      }
      > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows:
          155px minmax(min-content, 100px) minmax(min-content, 40px) min-content
          minmax(min-content, 40px) min-content;
        gap: 0px 20px;
        grid-auto-flow: row;
        grid-template-areas:
          'order-review customer-info shipping'
          'order-review delivery-info shipping'
          'order-review delivery-info comments'
          'order-review delivery-info payment'
          'coupon-code . payment'
          'coupon-code . actions';

        #ubercart_bundle_products_cart-pane {
          grid-area: order-review;
        }

        #coupon-pane,
        > :nth-child(2) {
          grid-area: coupon-code;
        }

        #customer-pane {
          grid-area: customer-info;
        }

        #delivery-pane {
          grid-area: delivery-info;
        }

        #uc_order_field-pane {
          grid-area: order-number;
        }

        // fieldset will be wrapped with a div without classname after click quote button
        #quotes-pane,
        > :nth-child(5) {
          grid-area: shipping;
        }

        #comments-pane {
          grid-area: comments;
        }

        // fieldset will be wrapped with a div without classname after click quote button
        > :nth-child(7),
        #payment-pane {
          grid-area: payment;
        }

        #edit-actions {
          grid-area: actions;
        }
      }
    }

    @include respond-above(xl) {
      > div {
        gap: 0px 57px;
      }
    }

    .panel-heading {
      padding: 0;
      border-bottom: 0;
      .panel-title {
        border-left: 0;
        font-size: 0;
        line-height: 1;
        padding: 0;
        display: none;
      }
      &::before {
        font-weight: 700;
        font-size: 18px;
        line-height: 151.69%;
        color: #2e2e2e;
      }
    }

    .btn,
    .button {
      background: #3f3f3f;
      border-color: #3f3f3f;
      border-radius: 3px;
      margin-bottom: 0;
    }

    #ubercart_bundle_products_cart-pane {
      .panel-heading {
        &::before {
          content: '1. REVIEW YOUR ORDER';
        }
      }
      .table-responsive {
        background-color: #f2f2f2;
        padding: 1rem 2rem;
      }
      .table {
        margin-bottom: 0;
        > thead > tr > th {
          background-color: #f2f2f2;
          font-weight: 500;
          font-size: 16px;
          line-height: 151.69%;
          color: #2e2e2e;
          border-bottom: 1px dashed #cacaca;
          abbr[title],
          abbr[data-original-title] {
            border-bottom: none;
          }
        }

        > tbody > tr {
          > td.subtotal,
          > td {
            background-color: #f2f2f2;
            border-bottom: 1px dashed #cacaca;
            font-weight: 400;
            padding: 1rem;
            font-size: 16px;
            line-height: 151.69%;
            color: #2e2e2e;
          }

          > td.subtotal {
            font-weight: 700;
            border-bottom: none;
          }
        }
      }
    }

    #coupon-pane {
      width: 100%;
      .help-block {
        display: none;
      }
      button {
        line-height: 1;
        padding: 8.5px 12px;
      }
      .panel-body {
        display: flex;
        align-items: flex-end;
        width: 100%;

        .form-item-panes-coupon-code {
          margin-right: 20px;
          flex: 1;
          input {
            border-radius: 3px;
          }
        }
      }
    }

    #customer-pane {
      margin-bottom: 1rem;
      .panel-heading {
        &::before {
          content: '2. CUSTOMER INFORMATION';
        }
      }

      .form-item-panes-customer-primary-email {
        display: flex;
        flex-direction: column;

        input {
          width: 100%;
          margin-bottom: 10px;
        }
        label {
          width: 100%;
          padding-bottom: 0;
        }
      }
    }

    #delivery-pane {
      .help-block {
        display: none;
      }
      .field-label {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        font-weight: 500;
        padding-bottom: 0;
      }

      .address-pane-table {
        table > tbody {
          display: flex;
          flex-wrap: wrap;
        }
        tr {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-bottom: 1rem;

          &.field-delivery_first_name {
            width: 50%;
            order: 0;
          }
          &.field-delivery_last_name {
            width: 50%;
            order: 1;
          }
          &.field-delivery_phone {
            order: 2;
          }
          &.field-delivery_company {
            order: 3;
          }

          &.field-delivery_street1,
          &.field-delivery_street2 {
            order: 4;
          }
          &.field-delivery_ucxf_cf_region {
            width: 50%;
            order: 5;
          }
          &.field-delivery_postal_code {
            width: 50%;
            order: 6;
          }
          &.field-delivery_country {
            order: 7;
          }
        }
      }

      .chosen-single {
        height: 3.3rem;
        padding: 3px 6px;
        background: none;
        border: none;
        div {
          top: 4px;
        }
      }
      .field-delivery_ucxf_leave_goods {
        display: none;
      }
    }

    #edit-field-purchase-order-number {
      .form-item {
        display: flex;
      }
    }

    #comments-pane {
      margin-bottom: 1rem;
      .help-block {
        display: none;
      }
      .form-textarea-wrapper.resizable {
        textarea {
          resize: none;
          height: 80px !important;
        }
        .grippie {
          display: none;
        }
      }
    }
    #quotes-pane {
      width: 100%;
      margin-bottom: 0;
      .panel-heading {
        &::before {
          content: '3. SHIPPING';
        }
      }

      button.form-submit {
        padding: 6px 12px;
        min-width: 300px;
      }
      img {
        display: none;
      }
      .collection-container {
        .collection-description {
          display: none;
        }
      }

      #quote {
        border: none;
        margin-top: 0;
        .form-item {
          border-top: 1px dashed #eaeaea;

          &:not(.form-item-panes-delivery-address-delivery-ucxf-leave-goods) {
            padding: 10px 15px;
          }

          > .control-label {
            display: flex;
          }
        }

        .field-delivery_ucxf_leave_goods {
          border-top: 1px dashed #eaeaea;
          display: inline-block;
          width: 100%;
          padding: 5px 15px;

          .field-field .form-item {
            border: none;
            .form-select {
              height: 3.4rem;
            }
          }
          .field-label {
            padding-right: 2rem;
            font-size: 0;
            &::before {
              content: 'Leave goods if premises are unattended?*';
              font-size: 16px;
            }
          }
        }
      }
    }
    #payment-pane {
      margin-top: 0;
      width: 100%;
      .panel-heading {
        &::before {
          content: '4. PAYMENT';
        }
      }
      img {
        max-width: 122px;
      }

      .form-item-panes-payment-payment-method .form-radios > .form-item {
        position: relative;
        .payment-method-option-info {
          a,
          p {
            display: none;
          }

          .payment-method-title {
            display: inline-block;
            margin-bottom: 10px;
          }
          .laybuy-checkout-logo,
          .zippay-checkout-logo,
          .afterpay-checkout-logo {
            position: absolute;
            top: 17px;
            right: 0;
          }
        }
        .payment-option__view-more {
          position: absolute;
          top: 20px;
          left: 120px;
          text-decoration: none;
          cursor: pointer;
          font-size: 0;
          &::before {
            content: 'view more';
            font-size: 12px;
          }
        }

        &.view-more {
          .payment-method-option-info {
            a {
              display: inline;
            }
            p {
              display: block;
            }
          }
          .payment-option__view-more::before {
            content: 'view less';
          }
        }
      }

      #line-items-div {
        padding: 1rem 2rem;
        background-color: #f2f2f2;
        margin-bottom: 1rem;
      }
      table#uc-order-total-preview {
        width: 100%;

        tbody {
          display: block;
        }

        tr {
          display: flex;
          justify-content: space-between;

          td {
            font-weight: 400;
            font-size: 16px;
            line-height: 205.7%;
            text-align: right;
            padding: 0;
            &.title {
              font-weight: 500;
              color: #2e2e2e;
            }
          }
        }
      }
    }

    .form-actions {
      #edit-cancel {
        display: none;
      }
      #edit-continue {
        width: 100%;
        background: #77b726;
        border-color: #77b726;
        border-radius: 3px;
        color: #fff;
        font-size: 0;
        &::before {
          content: 'Place Order';
          font-size: 16px;
        }
      }
    }
  }
}

.page--checkout.logged-in {
  #uc-cart-checkout-form {
    > div {
      display: flex;
      flex-direction: column;
    }
    @include respond-above(lg) {
      &.medium-order-summary > div {
        grid-template-rows: 150px minmax(min-content, 100px) min-content minmax(min-content, 40px) repeat(
            2,
            min-content
          );
        grid-template-areas:
          'order-review customer-info shipping'
          'order-review delivery-info shipping'
          'order-review delivery-info comments'
          'order-review delivery-info payment'
          'coupon-code order-number payment'
          '. . actions';
      }
      &.short-order-summary > div {
        grid-template-rows:
          150px minmax(min-content, 50px) minmax(min-content, 100px) minmax(min-content, 60px)
          repeat(2, min-content);
        grid-template-areas:
          'order-review customer-info shipping'
          'order-review delivery-info shipping'
          'order-review delivery-info comments'
          'coupon-code delivery-info payment'
          'coupon-code order-number payment'
          '. . actions';
      }
      > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 150px minmax(min-content, 100px) minmax(min-content, 100px) minmax(min-content, 40px) repeat(
            3,
            min-content
          );
        gap: 0px 20px;
        grid-auto-flow: row;
        grid-template-areas:
          'order-review customer-info shipping'
          'order-review delivery-info shipping'
          'order-review delivery-info comments'
          'order-review delivery-info payment'
          'order-review order-number payment'
          'coupon-code order-number payment'
          '. . actions';

        // fieldset will be wrapped with a div without classname after click quote button
        #quotes-pane,
        > :nth-child(6) {
          grid-area: shipping;
        }

        // fieldset will be wrapped with a div without classname after click quote button
        > :nth-child(8),
        #payment-pane {
          grid-area: payment;
        }
      }
    }

    @include respond-above(xl) {
      > div {
        gap: 0px 57px;
      }
    }

    #delivery-pane {
      .panel-heading {
        &::before {
          content: 'DELIVERY INFORMATION';
        }
      }
      #delivery-address-pane {
        > div {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          line-height: 174.69%;
          color: #2e2e2e;
          margin-bottom: 1rem;
        }
      }
      .help-block {
        display: block;
      }
    }
  }
}
