/*------------------------------------*\
  File: Base
\*------------------------------------*/

html {
    scroll-behavior: smooth;
    font-size: 62.5%;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body * {
    box-sizing: border-box;
}

a {
    @include myanimated;
    outline: none;
    &:active,
    &:focus {
        outline: none;
    }
}

ol.breadcrumb {
    display: none;
}

body.front .page_header {
    display: none;
}

section.page_header {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
}

.page_header {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
}

.nav-tabs {
    li {
        > a {
            border-bottom: 6px solid transparent;

            &:hover {
                border-color: transparent;
            }
        }

        &.active > a,
        &.active > a:hover {
            border-left: none;
            border-top: none;
            border-right: none;
            border-bottom: 6px solid $primary-color;
        }
    }
}

.block-title {
    position: relative;
    a {
        color: inherit;
    }
}

.alert-message,
.alert-success {
    background-color: lighten($orange-2, 10%);
    border-color: $orange-2;
    color: $white;
    a {
        color: $white;
    }
}

.panel {
    box-shadow: none;
    border: none;
    transition: none;
    border-radius: 0;
}
