.page-product-comparison .region-content {
  section.page_header {
    border: none;
  }

  #block-system-main > a {
    @include btn(#3e3e3e);
    display: inline-block;
    margin: 20px 10px;
  }
  .node-product.node-teaser {
    border: none;
    box-shadow: none;
    max-width: 32rem;
    margin: 0 auto;
  }

  table {
    width: 100%;

    tr {
      td {
        padding: 1.3rem 2rem;
        text-align: center;
        border-left: 1px solid #d4d4d4;
        &:first-child {
          text-align: left;
          border-left: none;
        }
      }
    }
  }

  @include respond-above(lg) {
    h1.page-header {
      font-size: 26px;
    }
    table {
      tr {
        th {
          padding: 1.3rem 2rem;
          font-weight: 700;
          font-size: 22px;
          line-height: 1.2;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          color: #3f3f3f;
          border-left: 1px solid #d4d4d4;

          &:first-child {
            border-left: none;
          }
        }

        &:first-child th {
          text-align: center;
        }

        &:nth-child(odd):not(:first-child) {
          td {
            background-color: #f1f8ff;
          }
        }
      }
    }
  }

  @include respond-below(lg) {
    table {
      tr {
        display: flex;

        &:not(:first-child) {
          flex-wrap: wrap;
        }

        &:first-child th:first-child {
          display: none;
        }
        > th {
          flex: 1;
          text-align: center;
          font-size: 22px;
          font-weight: 700;
          padding-bottom: 10px;
          .node-product.node-teaser {
            .field-name-title h2 {
              font-size: 13px;
            }
            .group-price-section,
            .add-to-cart {
              display: none;
            }
          }
        }

        > td {
          &:first-child {
            flex-basis: 100%;
            flex-shrink: 0;
            width: 100%;
            text-align: center;
            background-color: #f1f8ff;
          }

          &:nth-child(2) {
            border-left: none;
          }
          background-color: #fff;

          flex: 1;
        }
      }
    }
  }
}
