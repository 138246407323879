
.block-sharethis{
    @include wrapper(relative, 100%);
}
.sharethis-wrapper{
    @include wrapper(relative, 100%);
    display: flex;
    flex-direction: row;

    &::before{
        content: "SHARE:";
        @include wrapper(relative, auto);
        font-weight: 500;
        margin: 8px 8px 8px 0;
    }

    span{
        @include wrapper(relative, 35px, 35px);
        @include myanimated;
        border-radius: 100%;
        margin-right: 4px;
        background-color: $grey-7;
        display: block;
        cursor: pointer;
        overflow: hidden;

        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: $white;
            display: block;
            text-align: center;
            font-family: $font-family-icon-fa;
            font-size: 18px;
            line-height: 37px;
        }

        &:hover{
            background-color: $menu-color;
        }

        &.st_facebook_custom{
            &::before{
                content:"\f09a";
            }
        }

        &.st_twitter_custom{
            &::before{
                content:"\f099";
            }
        }

        &.st_email_custom{
            &::before{
                content:"\f0e0";
                font-size: 17px;
            }
        }

        &.st_sharethis_custom{
            &::before{
                content:"\f1e0";
            }
        }

        
      

        
    }
}