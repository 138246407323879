.content-bottom-banner {
  background-color: #424242;

  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 100;
  .bottom-banner__messages {
    text-align: center;
    margin: 1rem;
    color: $orange-3;
  }

  .product-compare-wrapper {
    width: 100%;
    display: none;
  }
  .product-compare-container {
  }

  .product-compare {
    display: flex;
    padding: 0.5rem 2rem;
    min-height: 15rem;
    align-items: center;
    text-transform: uppercase;

    @include respond-below(lg) {
      flex-direction: column;
    }

    &__title {
      font-weight: 700;
      font-size: 26px;
      line-height: 1.2;
      letter-spacing: 0.02em;
      color: #ffffff;
      width: 15rem;
      margin: 0;

      @include respond-above(xl) {
        margin-left: 30rem;
      }

      @include respond-below(lg) {
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        font-size: 20px;
      }
    }

    &__products {
      flex: 1;
      display: flex;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.2;
      letter-spacing: 0.02em;
      color: #ffffff;
      height: 10rem;
      margin: 0 2rem;
      justify-content: space-around;

      .compare-product-container {
        position: relative;
        padding-left: 9rem;
        max-width: 27rem;

        h5 {
          margin: 10px 0 0;
          font-size: 12px;
          font-weight: 500;
        }

        .compare-product-image {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 8rem;
          max-height: 9rem;
          margin: 1rem 0;
        }

        .remove-product-compare {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          color: #e87409;
          padding: 15px 0;
          display: inline-block;
          cursor: pointer;
        }
      }
      @include respond-below(lg) {
        margin: 15px 0;
        height: auto;

        .compare-product-container {
          padding: 0;
          display: flex;
          flex-direction: column;
          margin: 0 10px;

          .compare-product-image {
            position: relative;
            order: -1;
          }

          .remove-product-compare {
            padding: 5px 0 0;
          }
        }
      }

      @include respond-below(md) {
        .compare-product-container {
          margin: 0 5px;
        }
      }
    }

    &__button-container {
      display: flex;
      flex-direction: column;

      button,
      a {
        margin: 0 1rem 1rem;
        width: 18rem;
      }

      @include respond-below(lg) {
        flex-direction: row;
        flex-wrap: wrap;

        button,
        a {
          width: auto;
        }
      }
    }
  }

  #block-koda-commerce-extra-product-add-to-cart-block {
    display: none;

    @include respond-below(md) {
      background-color: #fff;
      color: #3f3f3f;
      border-top: 1px solid #f7f7f7;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    }
    #product-add-to-cart-block {
      display: flex;
      padding: 2rem 4rem 2rem 2rem;
      align-items: center;
      color: #fff;

      @include respond-above(lg) {
        padding: 2rem 10rem 2rem 10rem;
      }

      img.product-add-to-cart-product-image {
        max-height: 80px;
        max-width: 80px;
        margin-right: 40px;

        @include respond-below(md) {
          display: none;
        }
      }

      .product-add-to-cart-description {
        margin-right: auto;
        font-weight: 700;

        h2 {
          font-size: 22px;
          line-height: 1.2;
          letter-spacing: 0.02em;
          margin-top: 0;
        }

        h3 {
          font-size: 17px;
          line-height: 1.5;
          margin: 0;
          color: #bbbbbb;
        }

        @include respond-below(lg) {
          h2 {
            font-size: 13px;
          }

          h3 {
            font-size: 12px;
          }
        }

        @include respond-below(md) {
          display: none;
        }
      }

      .product-add-to-cart-actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        > .display-price {
          color: #fac23c;
          font-size: 18px;
          padding-bottom: 15px;
          padding-left: 0;
          .crossout-price {
            text-decoration: line-through;
            color: $grey-3;
          }
        }

        .form-actions.form-group {
          margin-bottom: 0;
          button {
            padding: 0.6rem 3.8rem;
            border-radius: 2.4px;
            span.icon {
              display: none;
            }
          }
        }

        @include respond-above(md) {
          .block-uc-cart {
            display: none;
          }
        }

        @include respond-below(md) {
          width: 100%;
          flex-direction: row;
          // flex-wrap: wrap;
          justify-content: center;

          .block-uc-cart {
            display: block;
            width: 60px;
            padding-top: 0;
            margin-right: 10px;
            h2 a {
              height: 28px;
              align-items: flex-start;
            }
            .cart-block-icon-full {
              background-size: auto;
              width: 32px;
              height: 28px;
              margin-right: 2px;
            }

            .cart-block-summary-items {
              z-index: 100;
              width: 26px;
              height: 26px;
              top: 3px;
              right: 2px;
              left: auto;
              > span {
                font-size: 14px;
                line-height: 23px;
              }
            }
          }

          > .display-price {
            flex: 1;
            color: #3f3f3f;
            padding: 0;
          }

          > form {
            flex-basis: 132px;
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.page-products .content-bottom-banner .product-compare-wrapper {
  display: block;
}
