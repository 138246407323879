.view-brands {
  @include wrapper(relative, 100%, 100%);
  float: left;
  text-align: center;

  .view-mode-term_teaser{
    @include wrapper(relative, 100%, 100%);
    @include flex-container(column, wrap, center, stretch, center);

    .field-type-image {
      align-items: center;
      justify-content: space-around;
      display: flex;
      flex-grow: 1;
      padding: 25px;
    }

    img {
      width: 100%;
      height: auto;
      max-height: 150px;
      object-fit: contain;
      //max-height: 100%;
    }  

    .field-name-brand-pruducts-link{
      @include wrapper(relative, 100%);
      text-align: center;  
      padding: 20px 0;    
      
      a{
        font-weight: 500;
        color: $black;
        font-size: 18px;
        background-color: $menu-color;
        padding: 8px 20px;
        border-radius: 4px;
        color: $white;

        &:hover{
          background-color: $primary-color;
          color: $white;
          text-decoration: none;
        }
      }
     
    }
  }

  table {
    width: 100%;
  }
  td {
    width: 25%;
    height: 250px;
    >div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }


}