.page--complete {
  .region-content {
    display: flex;
    flex-wrap: wrap;

    .confirmation-right,
    #block-system-main {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }

    #block-system-main {
      h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .order-details {
        border: 1px solid #d0d0d0;
        box-sizing: border-box;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
        width: 500px;
        padding: 20px 30px;
        margin: 30px 0;
        color: #3f3f3f;

        > div {
          display: flex;

          &:not(:last-child) {
            border-bottom: 1px solid #e4e4e4;
            justify-content: space-between;
          }

          &:last-child {
            flex-direction: column;
          }
          > label,
          > span {
            padding: 10px 0;
          }
        }
      }
    }

    .confirmation-right {
      text-align: center;
      h2 {
        font-size: 20px;
      }

      p {
        max-width: 250px;
      }

      > .btn {
        width: 380px;
      }
      h1 {
        text-transform: uppercase;
        letter-spacing: 4px;
        font-weight: 500;
        max-width: 250px;
        font-size: 26px;
        margin-bottom: 30px;
      }
      .social-media-link {
        width: 66px;
        display: inline-block;
        text-align: center;
      }
    }
  }
}
