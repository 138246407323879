.block-masquerade {
    @include wrapper(relative, 100%);
    float: left;
    display: block;

    &.masquerade-fixed {
        position: fixed;
        bottom: 0;
        background-color: rgba(143, 143, 143, 0.9);
        color: $white;
        width: 100%;
        padding: 4px;
        left: 0;
        z-index: 99;
    }

    .block-wrapper {
        @include wrapper(relative, 100%);
    }

    .block-title {
        display: none;
    }

    form {
        .dropdown {
            @include wrapper(absolute, 100%, 100%);
            z-index: 99;
            left: 0;
            top: -6px !important;
            bottom: 0 !important;
            height: 10px;
            margin: 0 !important;

            .dropdown-menu {
                width: 100%;
                height: auto;
                max-height: 200px;
                overflow-y: scroll;
                padding: 8px;
                background-color: #fff !important;
                border-radius: 0;
                top: auto;
                bottom: 0;
                z-index: 99;
                float: left;
                > li {
                    @include wrapper(relative, 100%);
                }
            }

            a {
                position: relative;
                float: left;
                width: 100%;
                display: block;
                padding: 0 8px;
                font-size: 12px !important;
            }
        }

        position: relative;
        @include wrapper(relative, 100%);

        > div {
            position: relative;
            width: 100%;
            height: 100%;
            @include flex-container(column, wrap, flex-start, stretch, center);
            @include respond-above(sm) {
                flex-direction: row;
            }
        }

        span {
            &.input-group-addon {
                border-top: 1px solid #ccc !important;
                border-right: 1px solid #ccc !important;
                border-bottom: 1px solid #ccc !important;
                border-radius: 0 !important;
            }
        }

        .input-group {
            display: flex;
            flex-direction: row;
        }

        .container-inline {
            position: relative;
            display: flex;
            flex-direction: row;
            margin-bottom: 0 !important;
            width: auto !important;
            align-items: center;

            .form-submit {
                height: 34px;
            }
        }

        .form-item {
            position: relative;
            @include flex-container(column, wrap, center, stretch, center);
            height: 100%;
            margin: 0;
            min-height: 40px;

            .description {
                height: 100%;
                padding: 0 8px;
                font-size: 12px;

                @include flex-container(row, wrap, center, stretch, center);
                a {
                    position: relative;
                    padding-left: 8px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    text-decoration: underline;
                }

                #quick_switch_links {
                    margin-left: 15px;
                    @include flex-container(row, wrap, center, stretch, center);
                }

                ul li {
                    margin-bottom: 0;
                }
            }
        }

        .form-control {
            position: relative;
            border-radius: 0;
            margin-bottom: 0 !important;
        }

        .input-group-addon {
            position: relative;
            width: 40px;
            padding: 0;
            border-radius: 0;
            border-color: $grey-2;

            @include flex-container(column, wrap, center, center, center);

            .glyphicon {
                font-size: 12px;
            }
        }

        .input-group {
            position: relative;
            display: flex;
            flex-direction: row;
        }

        .btn.form-submit {
            border-radius: 0;
            min-height: 35px;
        }
    }
}
