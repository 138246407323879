.hamburger {
  // adapted from https://codepen.io/erikterwan/pen/EVzeRP

  position: absolute;
  top: 15px;
  right: 10px;
  display: none;

  @include respond-below(lg) {
    display: inline-block;
  }

  span {
    display: block;
    height: 4px;
    margin: 6px 0;
    border-radius: 4px;
    width: 33px;
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:last-child {
      transform-origin: 0% 100%;
    }
  }
}

html.mm-opening .header {
  .hamburger span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
    cursor: pointer;

    &:nth-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
}

html:not(.mm-opening) .header {
  .hamburger span {
    background-color: $menu-color;

    &:first-child {
      transform-origin: 0% 0%;
    }
    &:last-child {
      transform-origin: 0% 100%;
    }
  }
}

html.mm-opened {
  .header_wrapper {
    z-index: 0;
  }
}
