/*------------------------------------*\
  File: Contact Page
\*------------------------------------*/
.page--contact,
.page-node-1682 {
  .content-wrapper > div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .region-content,
  .region-content-left {
    flex-basis: 0;
    flex-grow: 1;
  }

  .region-content {
    @include respond-above(md) {
      padding: 0 5%;
    }

    .contact-phone {
      font-size: 32px;
      font-weight: bold;
    }

    .local-phone span,
    .email span {
      font-weight: bold;
    }
  }

  .contact_details {
    @include wrapper(relative, 100%);
    display: block;
    @include respond-above(md) {
      padding-top: 30px;
    }
  }

  .contact_address {
    position: relative;
    width: 100%;
    iframe {
      height: 220px;
    }
  }

  .block-sharethis {
    @include wrapper(relative, 100%);
    .sharethis-wrapper {
      @include wrapper(relative, 100%);
      @include myanimated;
      margin-top: 15px;
      margin-bottom: 20px;

      span {
        @include myanimated;
        border: 2px solid $primary-color;
        background-color: $white;

        &::before {
          @include myanimated;
          color: $primary-color;
          line-height: 32px;
        }

        &:hover {
          border-color: $orange;
          &::before {
            color: $orange;
          }
        }
      }
    }
  }
}

.webform-client-form {
  @include wrapper(relative, 100%);
  @include respond-below(md) {
    margin-top: 30px;
  }

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond-above(sm) {
      margin-right: 20px;
    }

    .form-control {
      margin-bottom: 0;
      border-radius: 0;
      padding-top: 12px;
      padding-bottom: 12px;
      @include wrapper(relative, 100%);
    }

    .webform-submit {
      text-transform: uppercase;
      min-width: 164px;
      font-weight: 500;
      height: 48px;
      background-color: $primary-color;
      border-color: $primary-color;
    }

    .webform-component {
      width: 100%;
    }

    .webform-component--first-name,
    .webform-component--last-name,
    .webform-component--email-address,
    .webform-component--phone-number {
      @include respond-above(md) {
        width: 50%;
      }
    }

    .webform-component--first-name,
    .webform-component--email-address {
      @include respond-above(md) {
        padding-right: 10px;
      }
    }

    .webform-component--last-name,
    .webform-component--phone-number {
      @include respond-above(md) {
        padding-left: 10px;
      }
    }

    .webform-component--message textarea {
      height: 170px;
    }

    .form-group {
      margin-bottom: 20px;
    }
  }

  .form-actions {
    text-align: right;
    width: 100%;
  }
}
