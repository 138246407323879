body {
  > .content {
    flex-grow: 1;
    padding-bottom: 40px;
  }

  @include respond-above(lg) {
    .topimg {
      margin-top: 16.4rem;
    }

    &:not(.front) {
      > .content {
        margin-top: 18.4rem;
      }
    }
  }

  @include respond-below(lg) {
    &:not(.front) > .content,
    .topimg {
      margin-top: 19.4rem;
    }
  }

  @include respond-below(md) {
    &:not(.front) > .content,
    .topimg {
      margin-top: 11.5rem;
    }
  }

  .main-container {
    display: flex;
    width: 100%;
    .content-col {
      flex: 1;
      max-width: 100%;
    }

    .sidebar {
      position: sticky;
      align-self: flex-start;
      width: 275px;
      margin-right: 50px;
      top: 200px;
      overflow: auto;

      @include respond-above(md) {
        .region.region-sidebar-first {
          max-height: calc(100vh - 280px);
        }
      }

      @include respond-between(md, lg) {
        width: 250px;
        margin-right: 20px;
      }

      @include respond-below(md) {
        @include myanimated;
        position: fixed;
        padding-left: 0;
        padding-right: 0;
        width: 50%;
        left: -50%;
        top: 185px;
        bottom: 0;
        z-index: 101;
        background-color: $grey-9;

        &.show {
          left: 0;
        }

        .region.region-sidebar-first {
          max-height: 100%;
        }
      }
      @include respond-below(sm) {
        width: 100%;
        left: -100%;
      }

      .region-sidebar-first {
        margin-bottom: 0;
      }
    }
  }

  #sidebar-trigger {
    display: none;
  }
  &.page-taxonomy,
  &.page-specials,
  &.page-products {
    #sidebar-trigger {
      @include respond-below(md) {
        @include wrapper(fixed, 30px, 100vh);
        @include myanimated;
        // display: block;
        float: left;
        left: 0;
        top: 185px;
        z-index: 102;
        cursor: pointer;

        span {
          position: absolute;
          display: block;
          bottom: 0;
          top: 200px;
          padding-top: 4px;
          width: 200px;
          max-height: 30px;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: $grey-8;
          font-weight: bold;
          transform-origin: 0 0;
          transform: rotate(-90deg);
        }

        background-color: $white;
        border-right: 1px solid $grey-4;

        &:hover {
          background-color: $grey-3;
          span {
            color: $white;
          }
        }

        &.on {
          left: 50%;
          background-color: $white;
          border-left: 1px solid $grey-4;
          border-right: 1px solid $grey-4;

          span {
            color: $orange-2;
          }
        }
      }

      @include respond-below(sm) {
        &.on {
          left: 100%;
          margin-left: -30px;
          span {
            color: $orange-2;
          }
        }
      }
    }
  }

  .content-wrapper {
    display: flex;
    width: 100%;

    .region-content {
      flex: 1;
      max-width: 100%;
    }

    .region-content-right {
      flex-basis: 29.5rem;
      flex-shrink: 0;
      margin-left: 14rem;
    }

    @include respond-below(xl) {
      .region-content-right {
        margin-left: 5rem;
      }
    }

    @include respond-below(lg) {
      flex-direction: column;

      .region-content-right {
        margin-left: 0;
      }
    }
  }
}
