/*------------------------------------*\
    Component: Button
\*------------------------------------*/

@mixin btn($color) {
  // border-radius: 0.4rem;
  font-size: 1.6rem;
  border: 1px solid $color;
  border-radius: 0;
  font-weight: bold;
  padding: 1rem 2rem;
  transition: all 0.3s;
  display: inline-flex;
  min-width: 5rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: $color;

  i,
  span.icon {
    margin-right: 1rem;
    font-size: 1.4rem;
  }

  &:focus,
  &:active,
  &:hover,
  &:active:focus {
    background: lighten($color, 7%);
    border-color: lighten($color, 7%);
    color: #fff;
    text-decoration: none;
    outline: none;
  }

  @if $color == transparent {
    border: 1px solid #fff;
    &:focus,
    &:active,
    &:hover,
    &:active:focus {
      background: $orange-3;
      border-color: $orange-3;
      text-decoration: none;
      outline: none;
    }
  }

  @include respond-below(lg) {
    font-size: 1.5rem;
  }
}

.button,
.btn {
  @include btn($orange-3);
}

.btn-transparent {
  @include btn(transparent);
}

.button-primary,
.btn-primary {
  @include btn($orange-3);
}

.btn-secondary {
  @include btn($btn-secondary-bg);
  border-radius: 34px;
  padding: 0.9rem 2rem;
}

.btn-dark {
  @include btn($menu-color);
}

.btn-large {
  font-size: 2.2rem;
  font-weight: 900;
  padding: 1.2rem 2.6rem;

  @include respond-below(sm) {
    font-size: 2rem;
    padding: 1.2rem 1rem;
  }
}

.btn-small {
  font-size: 1.3rem;
  font-weight: 700;
  padding: 0.6rem 1.2rem;
}
