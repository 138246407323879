.region-content {
  .block {
    //&.features,
    //&.specials,
    //&.to-come,
    &.three-tile {
      @include wrapper(relative, 100%);
      float: left;
      display: block;
      margin-top: 10px;

      @include respond-above(xs) {
        margin-bottom: 30px;
      }

      &:hover {
        img {
          @include box-shadow(0px 7px 10px -5px rgba(0, 0, 0, 0.8));
        }
      }

      img {
        @include myanimated;
        display: block;
        position: relative;
        height: auto;
        width: 100% !important;
        object-fit: cover;
      }

      //@include respond-between(tablet, xl){
      //}

      @include respond-above(sm) {
        @include wrapper(relative, 33.3%);
        &.left {
          padding-right: 20px;
        }
        &.center {
          padding-left: 10px;
          padding-right: 10px;
        }
        &.right {
          padding-left: 20px;
        }
      }
    }

    &.block-views {
      display: block;
      float: left;
      @include wrapper(relative, 100%);
    }
  }
}

#block-block-2 {
  padding: 30px 20px 20px 20px;
  text-align: center;
  border: 8px solid #fff;
  font-size: 16pt;
  margin-bottom: 10px;

  background-image: url(/sites/default/files/weld1.jpg);
  color: #fff;
  a {
    color: #fff !important;
  }
}

#block-block-3 {
  padding: 30px 20px 20px 20px;
  text-align: center;
  border: 8px solid #fff;
  font-size: 16pt;
  margin-bottom: 10px;
  background-image: url(/sites/default/files/weld2.jpg);
  color: #fff;
  a {
    color: #fff !important;
  }
}

#block-block-4 {
  padding: 30px 20px 20px 20px;
  text-align: center;
  border: 8px solid #fff;
  font-size: 16pt;
  margin-bottom: 10px;

  background-image: url(/sites/default/files/weld3.jpg);
  color: #fff;
  a {
    color: #fff !important;
  }
}

.node-home-slide-show > .group-wrapper .group-slider-options .btn-secondary {
  display: none;
}
.node-home-slide-show > .group-wrapper {
  border-bottom: 1px solid #eee;
}
