.page-node.node-type-product {
  /*
  ======================================================
    Layout: Content  Section
  ======================================================
  */
  .group-content-section {
    align-items: center;
    display: flex;
    padding: 30px 0;
    align-items: flex-start;

    @include respond-below(lg) {
      flex-direction: column;
      align-items: center;
    }

    /*
    ======================================================
      Layout: Content Left Section
    ======================================================
    */
    .group-content-left-section {
      width: 40%;

      @include respond-below(xl) {
        width: 100%;
      }
    }

    /*
    ======================================================
      Layout: Content Right Section
    ======================================================
    */
    .group-content-right-section {
      width: 60%;
      max-width: 65rem;
      margin-right: auto;

      .field-name-title h2 {
        margin-top: 10px;
      }

      @include respond-below(xl) {
        width: 100%;
      }

      @include respond-below(md) {
        text-align: center;

        .field-name-title h2 {
          font-size: 25px;
          line-height: 1.2;
          font-weight: 500;
        }
      }

      /*
      ======================================================
        Group: Price Info Wrapper
      ======================================================
      */

      .product-info.model {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 5px;
        color: $color-product-teaser-sku;

        @include respond-below(md) {
          font-size: 18px;
          font-weight: 500;
        }
      }

      .price-breaking {
        display: inline-block;

        > div {
          background-color: $menu-color;
          color: #fff;
          padding: 0.4rem 1rem;
          border-radius: 4px;
          font-size: 16px;
          text-transform: capitalize;
          margin-bottom: 3px;
          > span {
            padding-right: 3px;
          }
        }
      }

      .afterpay-message-container,
      .laybuy-message-container,
      .zippay-message-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 3px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        color: #3f3f3f;

        &:last-child {
          margin-bottom: 20px;
        }
        strong {
          padding: 0 2px;
        }
        a {
          order: 1;
          text-decoration: none;
          &::before {
            content: 'Read more';
          }

          &::after {
            content: '\f08e';
            font-family: $font-family-icon-fa;
            padding-left: 4px;
          }

          @include respond-below(xxl) {
            &::before {
              content: none;
            }
          }
        }

        &::after {
          content: '';
          display: inline-block;
          width: 120px;
          height: 25px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          margin: 0 10px;
        }

        @include respond-below(sm) {
          font-size: 13px;

          strong {
            font-weight: 400;
          }

          &::after {
            width: auto;
            width: 105px;
            height: 22px;
          }
        }
      }
      .afterpay-message-container::after {
        background-image: url(../../images/logo-afterpay.png);
      }

      .zippay-message-container::after {
        background-image: url(../../images/logo-zip.png);
        width: 80px;
      }

      .laybuy-message-container::after {
        background-image: url(../../images/logo-laybuy.svg);
        background-size: auto 22px;
      }

      /*
      ======================================================
        Layout: Content Top Section
      ======================================================
      */

      .group-content-top-left-section {
        display: flex;
        width: calc(100% - 220px);
        text-align: center;
        float: left;
        @include respond-below(md) {
          width: 100%;
          justify-content: center;
        }
      }

      .group-content-top-right-section {
        float: right;
        width: 200px;
      }

      .display-price {
        font-weight: 700;
        font-size: 22px;
        line-height: 1.5;
        color: #d93232;
        text-align: left;
        display: flex;
        align-items: center;
        float: unset;
        clear: unset;
        margin: 15px 0;
        padding-left: 0;

        .crossout-price {
          margin-left: 10px;
        }

        .price-suffixes {
          margin-left: 3px;
          font-size: 16px;
        }

        @include respond-below(md) {
          font-size: 25px;
          justify-content: center;
          span:after {
            font-size: 25px;
          }
        }
      }

      .field-name-field-image {
        img {
          max-height: 70px;
          display: inline-block;
          width: auto;
        }
        .field-name-brand-pruducts-link {
          margin-top: 8px;
          font-size: 13px;
        }

        a,
        a:hover {
          color: $grey;
        }

        .field-label {
          display: none;
        }
      }

      .sharethis-wrapper {
        margin-top: 10px;
        &::before {
          content: 'Share:';
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5;
          color: #464646;
          margin: 0 3px 0 0;
        }
        span {
          width: 23px;
          height: 23px;

          &::before {
            font-size: 13px;
            line-height: 23px;
          }
        }
      }
      // }
      /*
      ======================================================
        Layout: Content Right Bottom Section
      ======================================================
      */
      .group-content-right-bottom {
        display: flex;
        justify-content: space-between;

        @include respond-below(md) {
          flex-direction: column;
          align-items: center;
        }
      }

      .group-content-right-bottom-right {
        width: 200px;
        @include respond-above(md) {
          margin-left: 2rem;
        }
      }

      .group-right-bottom-left {
        flex: 1;
      }

      /*
      ======================================================
        Group: Units Row
      ======================================================
      */
      .group-units-row {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        width: calc(100% - 200px);

        > div {
          margin-right: 14px;
        }

        @include respond-below(md) {
          justify-content: center;
          width: 100%;
        }
      }

      /*
      ======================================================
        Group: Actions Wrapper
      ======================================================
      */
      .group-actions-wrapper {
        position: relative;
        // max-width: 650px;
        // padding-bottom: 37px;
        margin-bottom: 20px;
        // width: calc(100% - 200px);
        width: 100%;

        @include respond-below(md) {
          max-width: 100%;
          width: 100%;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }

      /*
      ======================================================
        Field: Add to cart from
      ======================================================
      */
      .add-to-cart {
        width: 100%;
        background-color: #fafafa;
        padding: 0px 36px 15px;
        margin: 20px 0 0;

        @include respond-below(md) {
          padding: 0px 24px 10px;
          margin: 0;
        }

        .form-item-compare {
          display: none;
        }

        form > div {
          display: flex;
        }

        // @include respond-below(md) {
        // position: relative;
        // margin-bottom: 55px;
        // }

        .form-type-uc-quantity {
          align-items: center;
          display: flex;
          flex-direction: row;
          margin-top: 15px;

          input[type='text'] {
            padding: 6px 0;
            text-align: center;
            width: 4.6rem;
            height: 4.6rem;
            font-size: 2rem;
            margin: 0 15px;
          }

          label {
            margin-bottom: 0;
            font-size: 0;

            &::before {
              content: 'Qty:';
              font-size: 20px;
              font-weight: 500;
            }

            .form-required {
              display: none;
            }
          }
        }

        .form-actions {
          flex: 1;
          margin-top: 15px;
          position: relative;

          button {
            width: 100%;
            max-width: 550px;
            height: 4.6rem;
            border-radius: 3.62px;
            font-weight: 700;
            font-size: 20px;
            line-height: 1.5;

            @include respond-below(md) {
              font-size: 25px;
            }

            .icon {
              display: none;
            }
          }

          .uc-ajax-cart-alt-status-messages {
            .alert {
              animation: 1s fadeOut;
              animation-delay: 1s;
              animation-fill-mode: forwards;
              position: absolute;
              bottom: 35px;
              left: 0;
              > strong {
                display: none;
              }
            }
          }
        }

        // .form-item-quick-order {
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        //   margin-bottom: 0;

        //   @include respond-below(md) {
        //     bottom: -50px;
        //     width: 100%;
        //     label.control-label {
        //       margin: 0 auto;
        //     }
        //   }

        //   label.control-label {
        //     @include custom-checkbox('label-change');
        //     width: 215px;
        //     display: flex;
        //     align-items: center;
        //     .checkmark {
        //       display: flex;
        //       align-items: center;
        //       height: 37px;
        //     }

        //     .checkmark::before {
        //       content: '';
        //       background-image: url(../../images/icon-box-white.png);
        //       background-repeat: no-repeat;
        //       background-size: contain;
        //       width: 48px;
        //       height: 37px;
        //       position: relative;
        //     }

        //     .checkmark::after {
        //       content: 'Add to quick order';
        //       font-weight: 500;
        //       font-size: 18.5px;
        //       line-height: 20px;
        //       color: #202020;
        //     }

        //     input:checked ~ .checkmark::before {
        //       background-image: url(../../images/icon-quick-order.png);
        //     }

        //     input:checked ~ .checkmark:after {
        //       content: 'Quick order Added';
        //     }
        //   }
        // }

        > p {
          margin-bottom: 0;

          @include respond-below(md) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        .add-to-cart-in-stock {
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5;
          color: #779d0a;
          padding-right: 20px;
          position: relative;

          &::before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            height: 16px;
            width: 16px;
            background-color: #779d0a;
            border-radius: 50%;
            vertical-align: middle;
          }

          &::after {
            content: '';
            position: absolute;
            left: 6px;
            top: 4px;
            width: 4px;
            height: 9px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            display: inline-block;
          }
        }

        .ship-today {
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5;
          color: #252525;
        }
      }

      /*
      ======================================================
        Field: Notify in stock
      ======================================================
      */
      .field-name-product-enquiry-link,
      .field-name-product-enquiry-link-submitted,
      .field-name-notify-in-stock-submitted,
      .field-name-notify-in-stock {
        line-height: 1.5;

        a {
          @include btn($orange-3);

          width: 100%;
          max-width: 510px;
          height: 60px;
          border-radius: 3.62px;
          font-weight: 700;
          font-size: 25px;

          @include respond-below(md) {
            height: 4.6rem;
          }
        }

        span.notify-me-submitted {
          font-size: 16px;
          color: #d70404;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          color: #d70404;
        }
      }

      /*
      ======================================================
        Group: Actions Icons Wrapper
      ======================================================
      */

      .group-actions-icons-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 36.7rem;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #9f9f9f;
        margin-bottom: 2rem;

        label {
          font-weight: 500;
          margin-bottom: 0;
        }
        a,
        .btn {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          text-align: center;
          color: #9f9f9f;
          border: none;
          background: none;
          padding: 0;
        }

        > .field {
          width: 50%;
          padding: 0.8rem 1rem;
          border: 1px solid #e4e4e4;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            margin: 0;
          }
        }
        .field-name-free-delivery,
        .field-name-demo,
        .field-name-product-compare,
        .field-name-favourite,
        .field-name-price-match {
          &::before {
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            width: 23px;
            height: 23px;
            display: inline-block;
            margin-right: 5px;
          }
          p {
            margin-bottom: 0;
          }
        }

        .field-name-free-delivery {
          width: 100%;
          font-weight: 700;
          &::before {
            background-image: url(../../images/icon-free-delivery-new.png);
          }
        }

        .field-name-price-match {
          &::before {
            background-image: url(../../images/icon-price-match-new.png);
          }
        }

        .field-name-favourite {
          label {
            @include custom-checkbox('box');
            display: flex;
            align-items: center;
            padding-left: 29px;

            .checkmark {
              width: 23px;
              height: 23px;
            }

            .checkmark::before {
              background-image: url(../../images/icon-favourite.png);
              width: 23px;
              height: 23px;
            }

            input:checked ~ .checkmark::before {
              background-image: url(../../images/icon-quick-order.png);
            }

            input:checked ~ span {
              color: #009cde;
            }
          }
        }

        .field-name-demo {
          &::before {
            background-image: url(../../images/icon-demo.png);
          }
        }
        .field-name-product-compare {
          label {
            @include custom-checkbox('box');
            display: flex;
            align-items: center;
            padding-left: 29px;

            .checkmark {
              width: 23px;
              height: 23px;
            }

            .checkmark::before {
              background-image: url(../../images/icon-compare-new.png);
              width: 23px;
              height: 23px;
            }

            input:checked ~ .checkmark::before {
              background-image: url(../../images/icon-compare-new-checked.png);
            }

            input:checked ~ span {
              color: #009cde;
            }
          }
        }

        // &.with-quick-order {
        //   @include respond-above(md) {
        //     padding-left: 230px;
        //   }
        // }

        // @include respond-below(md) {
        //   flex-direction: column;
        //   align-items: center;
        //   width: 100%;
        //   padding-left: 0;
        //   margin-bottom: 0;
        // }
      }

      /*
      ======================================================
        Field: Price Match / Free Delivery
      ======================================================
      */
      // .field-name-free-delivery .field-item,
      // .field-name-price-match a.btn {
      //   background-color: transparent;
      //   border: none;
      //   color: inherit;
      //   font-weight: 500;
      //   font-size: 18.5px;
      //   line-height: 1;
      //   color: #202020;
      //   display: flex;
      //   align-items: center;
      //   padding: 0 20px 0 0;

      //   &::before {
      //     content: '';
      //     background-image: url(../../images/icon-price-match.png);
      //     background-repeat: no-repeat;
      //     width: 48px;
      //     height: 37px;
      //     display: inline-block;
      //     margin-right: 10px;
      //   }

      //   @include respond-below(md) {
      //     width: 215px;

      //     margin: 10px 0;
      //     justify-content: flex-start;

      //     &::before {
      //       background-position: -7px 0;
      //     }
      //   }
      // }

      // .field-name-price-match a.btn::before {
      //   background-position: -8px 0;
      // }

      // .field-name-free-delivery .field-item::before {
      //   background-image: url(../../images/icon-free-delivery.png);
      //   background-size: contain;
      // }

      /*
      ======================================================
        Field: Request Demo
      ======================================================
      */
      // .field-name-demo {
      //   margin-bottom: 2rem;
      //   .field-item > p {
      //     display: flex;

      // &::after {
      //   content: 'Click here to enter your details';
      //   margin-left: 15px;
      //   font-size: 14px;
      //   line-height: 1.5;
      //   color: #5b5b5b;
      // }

      // @include respond-below(md) {
      //   flex-direction: column;
      //   align-items: center;
      //   margin-top: 10px;

      //   &::after {
      //     width: 215px;
      //   }
      // }
      // }
      // a.product-enquiry-link:hover,
      // a.product-enquiry-link {
      //   background-color: transparent;
      //   border: none;
      //   color: #248bd6;
      //   font-weight: 400;
      //   font-size: 14px;
      //   line-height: 1.5;
      //   padding: 0;
      //   display: flex;
      //   text-decoration: none;

      //   &::before {
      //     content: '';
      //     background-image: url(../../images/icon-enquiry.svg);
      //     background-repeat: no-repeat;
      //     background-size: contain;
      //     width: 16px;
      //     height: 16px;
      //     display: inline-block;
      //     margin-right: 5px;
      //   }

      //   @include respond-below(md) {
      //     font-size: 22px;
      //     line-height: 28px;
      //     margin-bottom: 5px;
      //     width: 215px;

      //     &::before {
      //       width: 28px;
      //       height: 28px;
      //       margin-right: 9px;
      //     }
      //   }
      // }
      // }

      /*
      ======================================================
        Field: Short Description
      ======================================================
      */
      .field-name-field-short-description {
        margin-bottom: 20px;
        ul {
          padding-left: 20px;
        }

        @include respond-below(md) {
          text-align: left;
        }
      }

      /*
      ======================================================
        Field: More Info Link
      ======================================================
      */
      .field-name-product-more-info-link {
        // float: left;
        a.btn-secondary {
          margin-top: 0rem;
          height: 35px;
          font-size: 15px;
          font-weight: 500;
        }
        @include respond-below(md) {
          text-align: center;
          margin-top: 2rem;
        }
      }

      /*
        ======================================================
          Product Compare checkbox
        ======================================================
        */
      .field-name-product-compare {
        float: left;
        padding: 10px 20px;
        font-weight: 600;
        .checkmark {
          input {
            margin: 0;
          }
          &::after {
            content: 'Compare';
            margin-left: 5px;
          }
        }
        @include respond-below(sm) {
          clear: both;
        }
      }
    }
  }
  /*
  ======================================================
    Layout: Content Right Section End
    Layout: Content  Section End
  ======================================================
  */

  /*
  ======================================================
    Carousels
  ======================================================
  */
  .field-name-recommended-product-slick-view,
  .field-name-field-related-products {
    display: inline-block;
    width: 100%;
    h2.block-title,
    .field-label {
      font-weight: 500;
      font-size: 20px;
      line-height: 132.3%;
      color: #3f3f3f;
      position: relative;
      margin-bottom: 20px;
      margin-top: 20px;

      &::after {
        content: '';
        border-top: 3px solid #e5e5e5;
        position: absolute;
        top: 10px;
        left: 170px;
        right: 0;
      }

      @include respond-below(md) {
        font-weight: 700;
        font-size: 25px;
        line-height: 132.3%;
        color: #202020;
        margin-bottom: 20px;
        margin-top: 0;
        padding-top: 25px;
        border-top: 1px solid #c6c6c6;

        &::after {
          content: none;
        }
      }
    }
  }

  .field-name-recommended-product-slick-view h2.block-title::after {
    left: 230px;
  }
  /*
  ======================================================
    Product More Info Anchor
  ======================================================
  */
  #product-more-info-tabs {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;

    @include respond-below(md) {
      top: -150px;
    }
  }

  /*
  ======================================================
    Group: Product Tabs
  ======================================================
  */
  .group-prodcut-tabs {
    margin-top: 20px;
    display: inline-block;
    width: 100%;

    .products-carousel.slick .slick__arrow {
      .slick-prev {
        left: -25px;
      }
      .slick-next {
        right: -25px;
      }
    }

    .horizontal-tabs-list {
      border-bottom: 1px solid $grey-1;
      margin: 10px 0;
      > li {
        border-bottom: 6px solid transparent;
        margin-right: 20px;
        &.selected {
          border-bottom-color: $primary-color;
        }

        a strong {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #3f3f3f;
        }
      }

      @include respond-below(sm) {
        display: flex;
        flex-direction: column;

        > li {
          border: 1px solid #b0b0b0;
          margin-right: 0;
          a {
            padding: 18px 0 16px;
            text-align: center;

            strong {
              font-weight: 500;
              font-size: 22px;
            }
          }
          &.selected {
            border-bottom: 1px solid #b0b0b0;
            background-color: #009cde;

            a strong {
              color: #fff;
            }
          }
        }
      }
    }
    .horizontal-tabs-panes {
      max-width: 100%;
      overflow-x: auto;
    }
  }
  iframe.media-youtube-player {
    width: 800px;
    height: 600px;

    @include respond-below(lg) {
      width: 600px;
      height: 450px;
    }

    @include respond-below(md) {
      width: 400px;
      height: 300px;
    }

    @include respond-below(sm) {
      width: 100%;
      height: auto;
    }
  }
}
