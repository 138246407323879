.view-homepage-brands {
  .slick__slide > ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    img {
      margin: auto;
    }

    li {
      list-style-type: none;
      width: 100%;

      @include respond-above(xs) {
        width: 20%;
      }
    }
  }
}
