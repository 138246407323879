// Theme colours (custom)
$color-product-teaser-title: #000000;
$color-product-teaser-sku: #505050;
$bottom-banner-color: #317ec4;
$bottom-banner-button-color: #3e3e3e;
$bottom-banner-button-color-hover: lighten($bottom-banner-button-color, 10%);
$featured-color: #019cde;
$footer-color: #424242;
$menu-color: #24518a;
$menu-highlight-color: #3cc3fb;

// Ordinary colours
$black: black;
$grey: #8f8f8f;
$grey-1: #f0f0f0;
$grey-2: #f2f2f2;
$grey-3: #9a9a9a;
$grey-4: #f5f5f5;
$grey-5: #6e6e6e;
$grey-6: #3d3d3d;
$grey-7: #b0b0b0;
$grey-8: #5e5e5e;
$grey-9: #fafafa;
$grey-10: #a8a8a8;
$white: #fff;

$orange: #ff5308;
$orange-2: #f59129;
$orange-3: #e7760e;

$red: #d70404;

$green: #68962e;

$special-color: $red;
$clearance-color: $green;

$uc-price-color: $red;
$uc-cart-btn-color: $orange-3;
$uc-product-stock: $green;

$top-banner-bg: $grey-6;

$color-primary: $orange-2;
$color-secondary: $orange-2;
$color-base: #000000;
$primary-color: $orange-2;
$primary-color-hover: darken($primary-color, 10%);
$product-header-highlight-color: #e4e4e4;

$search-border-color: rgba(74, 124, 189, 0.4);
$search-input-color: #acacac;

$font-family-Roboto: 'Roboto', sans-serif;
$font-family-icon: 'Material Icons';
$font-family-icon-fa: 'FontAwesome';
$font-awesome: 'FontAwesome';

$font-size-base: 15px;

$btn-primary-bg: $orange;
$btn-secondary-bg: #317ec4;
$btn-primary-border: darken($btn-primary-bg, 5%);
$btn-primary-hover: darken($btn-primary-bg, 10%);

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
//$grid-float-breakpoint:     $screen-sm-min !default;
$grid-gutter-width: 40px;
$grid-gutter-width-sm: $grid-gutter-width;
$grid-gutter-width-md: $grid-gutter-width;

$screen-xs: 640px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1600px;
$screen-xl-min: $screen-xl;

$container-large-desktop: ($screen-lg + $grid-gutter-width) !default;

$grid-gutter-width-sm: 40px;
$grid-gutter-width-md: $grid-gutter-width;

$grid-margin-width-mb: 16px;
$grid-margin-width-sm: 36px;
$grid-margin-width-md: 32px;
$grid-margin-width-lg: 36px;

$grid-top-spacing-sm: 30px;
$grid-top-spacing-lg: 40px;

//Top Banner Variables
$top-banner-h: 65vh;

$grid-breakpoints: (
  xs: 0,
  sm: $screen-xs,
  md: $screen-sm,
  lg: $screen-md,
  xl: $screen-lg,
  xxl: $screen-xl,
  mobile: $screen-xs,
  tablet: $screen-sm,
  desktop: $screen-md,
  normal: $screen-lg,
  wide: $screen-xl,
);

//$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-sans-serif: $font-family-Roboto;
