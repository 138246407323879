.group-title-section {
  align-items: flex-start;
  border-bottom: 2px solid $grey-1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 18px 0;

  @include respond-above(xs) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .field-name-title {
    border-left: 7px solid $primary-color;
    padding-left: 15px;
    margin: 0;

    @include respond-below(xs) {
      margin: 0 0 15px 0;
    }

    h2 {
      text-transform: uppercase;
      font-size: 24px;
      margin: 8px 0;
    }
  }
}
