/*------------------------------------*\
  File: News
\*------------------------------------*/

/*
======================================================
  Page: News
======================================================
*/
.page-news {
  #edit-category-wrapper {
    width: 100%;
    margin-bottom: 20px;

    .views-widget {
      width: 100%;
    }
    #edit-category {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond-below(sm) {
        flex-direction: column;
      }

      > .radio {
        margin: 0;
        border-bottom: 1px solid #f0f0f0;
        label.control-label {
          position: relative;
          padding: 5px 10px 13px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #3f3f3f;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            margin: 0;
          }

          &.selected::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 8px;
            bottom: 0;
            left: 0;
            display: inline-block;
            background-color: $orange-2;
          }
        }
      }
    }
  }

  .view-content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 5rem;
    column-gap: 4.5rem;

    @include respond-below(xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 3rem;
    }

    @include respond-below(lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include respond-below(sm) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .news__teaser {
      position: relative;
      margin-bottom: 5rem;
      max-width: 480px;

      &__content {
        position: absolute;
        bottom: -5rem;
        left: 2rem;
        right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #ffffff;
        padding: 1.5rem 2rem;
        border: 0.613391px solid #cfcfcf;
        box-sizing: border-box;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
        height: 12rem;
      }

      &__date {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        color: #9b9b9b;
        margin: 0;
      }

      &__title {
        margin: 0;

        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 1.2;
          letter-spacing: 0.423823px;
          color: #343434;
          text-decoration: none;
        }
      }
    }
  }
}

/*
======================================================
  Node: News
======================================================
*/
.node-type-news {
  // News Node Title
  section.page_header {
    border: none;
  }

  // News Node Content
  .news__page__image {
    margin-bottom: 2rem;
  }
  .field-name-body,
  .field-name-field-summary-on-top {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 162%;
    color: #464646;
    letter-spacing: 0.5px;
  }

  // News Right Side Links
  .news__sidebar {
    letter-spacing: 0.5px;

    h2.block-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.5;
      color: #343434;
      padding: 2rem 3rem;
      margin: 0;
      border-bottom: 1px dashed #dadada;
    }
    .view-content {
      .views-row {
        padding: 1rem 2rem 1rem 3rem;
        border-bottom: 1px dashed #dadada;
        border-left: 6px solid transparent;
        a {
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
          color: #0c71ce;
          text-decoration: none;
          display: inline-block;
          width: 100%;
        }

        &:hover {
          border-left: 6px solid #f59129;
        }
      }
    }
  }

  .sharethis-wrapper {
    padding: 1rem 2rem 1rem 3rem;
    margin-top: 2rem;

    &::before {
      content: 'Share:';
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.5;
      color: #464646;
    }
  }

  // Image group
  .news--image {
    @include wrapper(relative, 100%);
    margin-top: 2rem;
    margin-bottom: 2rem;
    img {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .field-name-body {
    img {
      max-width: 100%;
    }
  }

  .field-name-field-image-group {
    @include wrapper(relative, 100%);
    margin-top: 2rem;
    margin-bottom: 2rem;

    > .field-items {
      @include wrapper(relative, 100%);
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      > .field-item {
        display: flex;
        width: 100%;
        align-items: stretch;
        padding: 2rem;

        overflow: hidden;
        &:hover {
          img {
            @include scale(1.04);
          }
        }

        @include respond-above(lg) {
          flex-basis: 25%;
        }
        @include respond-between(sm, lg) {
          flex-basis: 50%;
        }

        img,
        iframe {
          width: 100%;
          @include myanimated;
        }

        .field-collection-view {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          border: unset;
        }
      }
    }
  }
}
