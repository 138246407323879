.block-wrapper {
    @include wrapper(relative, 100%);
    float: left;
}

.well {
    border: none;
    box-shadow: none;
    background-color: $grey-9;
    padding: 20px 15px;
}

#back-to-top {
    position: fixed;
    opacity: 0;
    bottom: -20%;
    float: right;
    right: 15px;
    z-index: 99;
    cursor: pointer;
    background-color: $orange-2;

    width: 40px;
    height: 40px;

    @include respond-above(xs) {
        width: 56px;
        height: 56px;
    }

    &:hover {
        background-color: $primary-color-hover;
    }

    &::before {
        content: '\f106';
        position: absolute;
        font-family: $font-family-icon-fa;
        top: 0;
        bottom: 0;
        color: $white;
        text-align: center;

        width: 40px;
        height: 40px;
        font-size: 35px;
        line-height: 40px;

        @include respond-above(xs) {
            width: 56px;
            height: 56px;
            font-size: 40px;
            line-height: 56px;
        }
    }

    &.show {
        bottom: 95px;
        opacity: 1;
        z-index: 1000;

        @include respond-below(md) {
            bottom: 80px;
        }
    }
}

.tabs--primary {
    //padding-bottom: 25px;
    > li {
        a {
            &:active,
            &:focus,
            &:hover {
                background-color: transparent;
            }
        }
    }
}

#mm-blocker {
    z-index: 999 !important;
}

// User Pass
form#user-login,
form#user-pass {
    margin: 30px auto 0;
    @include wrapper(relative, 100%);
    @include respond-above(xs) {
        .form-group {
            width: 300px;
        }
    }

    button {
        &.form-submit {
            background-color: $primary-color;
            border-color: $primary-color;
            &:hover {
                background-color: $primary-color-hover;
            }
        }
    }
}

body.logged-in.admin-menu {
    @include respond-below(md) {
        padding-top: 40px;
    }
}

.slicknav_menu {
    @include respond-below(md) {
        @include wrapper(fixed, 100%);
        z-index: 999;
        top: 0;
    }
}

.gear-loader {
    @include wrapper(relative, 100%);
    background: url('../images/loader-gear.svg') 0 center no-repeat;
    background-size: 15px;
    padding-left: 20px;
    border: none;
    font-weight: bold;
}

.gear-loader-2 {
    @include wrapper(fixed, 100%, 100%);
    @include flex-container(column, wrap, center, stretch, center);
    background: url('../images/loader-gear-large.svg') center center no-repeat;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    z-index: 99;
}

// Global Table
table {
    tr {
        td,
        th {
            &.remove {
                text-align: center;
            }
        }

        th {
            &.active {
                position: relative;
                background-color: $grey-3 !important;
                color: $white;
                a,
                span {
                    color: $white !important;
                }
            }
        }
    }
}

.ctools-use-modal {
    color: $black;
    font-weight: 500;

    &:hover {
        text-decoration: none;
        color: $orange-3;
    }

    &:not(:first-child) {
        padding-left: 15px;
    }
}

.ctools-modal-wishlist-to-checkout {
    width: auto;
    @include fa-icon('\f07a', $white);
}

table {
    td {
        .ctools-modal-update-wishlist-item-qty {
            @include fa-icon('\f00c');
        }

        .ctools-modal-remove-wishlist-item,
        .ctools-modal-remove-wishlist {
            @include fa-icon('\f00d');
        }

        .ctools-modal-wishlist-to-checkout {
            @include fa-icon('\f07a');
        }
    }
}

.enq-btn {
    background-color: $white;
    border: 1px solid $primary-color;
    border-radius: 4px;
    color: $primary-color;
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 6px 24px;
    text-align: center;
    transition: 0.3s;

    &:hover {
        background-color: lighten($orange-2, 25%);
        border-color: $primary-color-hover;
        color: $white;
    }
}

.block-masquerade {
    @include wrapper(relative, 100%);
    float: left;
    display: block;

    form {
        display: flex;
        flex-direction: row;

        > div {
            //@include wrapper(relative, 100%);
        }

        > div .container-inline {
            display: flex;
            flex-direction: row;
            @include wrapper(relative, 100%);
            .form-submit {
                height: 34px;
            }
        }

        span {
            &.input-group-addon {
                border: 1px solid $grey-4;
                border-radius: 4px;
            }
        }
    }

    .dropdown {
        top: -100% !important;
        left: 0 !important;
    }
}

/*
======================================================
Common click show
======================================================
*/
.checkout-click-show {
    border-bottom: none !important;
    > .panel-title {
        border-left: none !important;
        padding-left: 0 !important;
        text-transform: capitalize !important;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::before {
            content: '';
            width: 15px;
            height: 15px;
            margin-right: 8px;
            border: 1px solid #ccc;
        }
    }

    &.selected {
        > .panel-title {
            &::before {
                content: '\f00c';
                font-family: $font-family-icon-fa;
                line-height: 0.6;
                font-weight: normal;
                font-size: 17px;
                color: $orange;
            }
        }
    }

    .panel-body {
        @include myanimated;
    }
}

/*
======================================================
General Styles
======================================================
*/

body {
    section.page_header {
        border: none;
        h1.page-header {
            border: none;
            font-weight: 500;
            font-size: 3.2rem;
            line-height: 118.69%;
            letter-spacing: 0.02em;
            color: #3f3f3f;
            margin: 1.5rem 0 2.5rem 0;
            padding: 0;
            text-align: center;
        }
    }

    &.page--stock-notify-confirmation,
    &.page--contact-confirmation,
    &.page--product-enquiry-confirmation,
    &.page--subscription-confirmation {
        h1.page-header {
            display: none;
        }
    }
}
