.backdrop-default {
  background-color: rgba(0, 0, 0, 0.39) !important;
}

.ctools-modal-dialog.modal-dialog {
  @include respond-above(md) {
    width: 814px !important;
  }
}

.modal-body {
  position: relative;
  width: 100% !important;
  max-height: unset !important;
}

.modal-default {
  .modal-header {
    @include wrapper(relative, 100%);
    h4 {
      background-color: $menu-color;
      color: $white;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: bold;
      @include wrapper(relative, 100%);

      span {
        font-weight: normal;
      }
    }

    .close {
      position: absolute;
      right: 0px;
      top: -10px;
      color: $black;
      opacity: 1;
      font-weight: normal;

      &:focus {
        outline: 0;
      }
    }
  }

  .modal-content {
    @include wrapper(relative, 100%);
    padding: 20px 15px;
    border-radius: 0;

    .field-items,
    .field-item {
      @include wrapper(relative, 100%);
    }

    .block-title {
      display: none;
      opacity: 1;
      text-shadow: none;
    }

    .webform-client-form {
      @include wrapper(relative, 100%);
    }

    .form-control,
    .button-primary {
      border-radius: 0;
    }

    .form-actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}
