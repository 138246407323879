.node-type-product-collection.page-node {
  h1.page-header {
    display: none;
  }
  .group-product-section {
    display: flex;
    flex-direction: column;

    .field-name-field-collection-image {
      img {
        max-width: 700px;
        width: 100%;
      }
    }
    @include respond-above(xl) {
      flex-direction: row;
      .field-name-field-collection-image {
        width: 40%;
        padding: 0 50px;
      }

      .group-content-section {
        width: 60%;
      }
    }
  }
  /*
    ======================================================
      Product Collection Info
    ======================================================
  */

  .group-content-bottom {
    display: flex;
    flex-wrap: wrap-reverse;

    @include respond-below(md) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  .group-left-content {
    width: 50%;

    @include respond-below(xxl) {
      width: 100%;
    }
    .panel-heading {
      display: none;
    }
    .panel-body {
      padding: 0;

      .help-block {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        color: #252525;
        margin-top: 0;
        display: flex;

        @include respond-below(xxl) {
          flex-direction: column;
          width: 100%;
        }

        @include respond-below(md) {
          align-items: center;
        }

        span {
          color: #779d0a;
          padding-right: 10px;
          position: relative;
          &::before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            height: 16px;
            width: 16px;
            background-color: #779d0a;
            border-radius: 50%;
            vertical-align: middle;
          }

          &::after {
            content: '';
            position: absolute;
            left: 6px;
            top: 4px;
            width: 4px;
            height: 9px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            display: inline-block;
          }
        }
      }
    }
  }

  .group-actions-icons-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 36.7rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #9f9f9f;
    margin-bottom: 2rem;

    label {
      font-weight: 500;
      margin-bottom: 0;
    }
    a,
    .btn {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: #9f9f9f;
      border: none;
      background: none;
      padding: 0;
    }

    .field-name-free-delivery,
    .field-name-demo,
    .field-name-product-compare,
    .field-name-favourite,
    .field-name-price-match {
      width: 50%;
      padding: 0.8rem 1rem;
      border: 1px solid #e4e4e4;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        background-repeat: no-repeat;
        background-position: center;
        width: 23px;
        height: 23px;
        display: inline-block;
        margin-right: 5px;
      }
      p {
        margin-bottom: 0;
      }
    }

    .field-name-free-delivery {
      width: 100%;
      font-weight: 700;
      &::before {
        background-image: url(../../images/icon-free-delivery-new.png);
      }
    }

    .field-name-price-match {
      &::before {
        background-image: url(../../images/icon-price-match-new.png);
      }
    }

    .field-name-favourite {
      label {
        @include custom-checkbox('box');
        display: flex;
        align-items: center;
        padding-left: 29px;

        .checkmark {
          width: 23px;
          height: 23px;
        }

        .checkmark::before {
          background-image: url(../../images/icon-favourite.png);
          width: 23px;
          height: 23px;
        }

        input:checked ~ .checkmark::before {
          background-image: url(../../images/icon-quick-order.png);
        }

        input:checked ~ span {
          color: #009cde;
        }
      }
    }

    .field-name-demo {
      &::before {
        background-image: url(../../images/icon-demo.png);
      }
    }
    .field-name-product-compare {
      label {
        @include custom-checkbox('box');
        display: flex;
        align-items: center;
        padding-left: 29px;

        .checkmark {
          width: 23px;
          height: 23px;
        }

        .checkmark::before {
          background-image: url(../../images/icon-compare-new.png);
          width: 23px;
          height: 23px;
        }

        input:checked ~ .checkmark::before {
          background-image: url(../../images/icon-compare-new-checked.png);
        }

        input:checked ~ span {
          color: #009cde;
        }
      }
    }
    // display: flex;
    // margin-bottom: 20px;

    // @include respond-below(md) {
    //   flex-direction: column;
    //   align-items: center;
    //   width: 100%;
    // }
    // .field-name-free-delivery .field-item,
    // .field-name-price-match a.btn {
    //   background-color: transparent;
    //   border: none;
    //   color: inherit;
    //   font-weight: 500;
    //   font-size: 18.5px;
    //   line-height: 1;
    //   color: #202020;
    //   display: flex;
    //   align-items: center;
    //   padding: 0 20px 0 0;

    //   &::before {
    //     content: "";
    //     background-image: url(../../images/icon-price-match.png);
    //     background-repeat: no-repeat;
    //     width: 48px;
    //     height: 37px;
    //     display: inline-block;
    //     margin-right: 10px;
    //   }

    //   @include respond-below(md) {
    //     width: 215px;

    //     margin: 10px 0;
    //     justify-content: flex-start;

    //     &::before {
    //       background-position: -7px 0;
    //     }
    //   }
    // }

    // .field-name-price-match a.btn::before {
    //   background-position: -8px 0;
    // }

    // .field-name-free-delivery .field-item::before {
    //   background-image: url(../../images/icon-free-delivery.png);
    //   background-size: contain;
    // }
  }

  // .field-name-demo {
  //   margin-bottom: 2rem;
  //   .field-item > p {
  //     display: flex;

  //     &::after {
  //       content: 'Click here to enter your details';
  //       margin-left: 15px;
  //       font-size: 14px;
  //       line-height: 1.5;
  //       color: #5b5b5b;
  //     }

  //     @include respond-below(md) {
  //       flex-direction: column;
  //       align-items: center;
  //       margin-top: 10px;

  //       &::after {
  //         width: 215px;
  //       }
  //     }
  //   }
  //   a.product-enquiry-link:hover,
  //   a.product-enquiry-link {
  //     background-color: transparent;
  //     border: none;
  //     color: #248bd6;
  //     font-weight: 400;
  //     font-size: 14px;
  //     line-height: 1.5;
  //     padding: 0;
  //     display: flex;
  //     text-decoration: none;

  //     &::before {
  //       content: '';
  //       background-image: url(../../images/icon-enquiry.svg);
  //       background-repeat: no-repeat;
  //       background-size: contain;
  //       width: 16px;
  //       height: 16px;
  //       display: inline-block;
  //       margin-right: 5px;
  //     }

  //     @include respond-below(md) {
  //       font-size: 22px;
  //       line-height: 28px;
  //       margin-bottom: 5px;
  //       width: 215px;

  //       &::before {
  //         width: 28px;
  //         height: 28px;
  //         margin-right: 9px;
  //       }
  //     }
  //   }
  // }

  .field-name-product-more-info-link {
    a.btn-secondary {
      display: inline-block;
      width: auto;
    }

    @include respond-below(md) {
      text-align: center;
      a.btn-secondary {
        height: 55px;
        font-size: 22px;
        font-weight: 500;
      }
    }
  }

  // Middle content
  .group-middle-content {
    width: 30%;

    @include respond-below(xxl) {
      width: 50%;
    }

    @include respond-below(md) {
      text-align: center;
    }
  }

  .afterpay-message-container,
  .laybuy-message-container,
  .zippay-message-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #3f3f3f;

    @include respond-below(md) {
      justify-content: center;
    }

    &:last-child {
      margin-bottom: 20px;
    }
    strong {
      padding: 0 2px;
    }
    a {
      order: 1;
      text-decoration: none;
      &::before {
        content: 'Read more';
      }

      &::after {
        content: '\f08e';
        font-family: $font-family-icon-fa;
        padding-left: 4px;
      }
    }

    &::after {
      content: '';
      display: inline-block;
      width: 120px;
      height: 25px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      margin: 0 10px 0 0;
    }

    @include respond-below(sm) {
      font-size: 13px;

      strong {
        font-weight: 400;
      }

      &::after {
        width: auto;
        width: 105px;
        height: 22px;
      }
    }
  }

  .afterpay-message-container::after {
    background-image: url(../../images/logo-afterpay.png);
  }

  .zippay-message-container::after {
    background-image: url(../../images/logo-zip.png);
    width: 80px;
  }

  .laybuy-message-container::after {
    background-image: url(../../images/logo-laybuy.svg);
    background-size: auto 22px;
  }

  .field-name-share-this {
    width: 20%;
    order: 3;
    margin-bottom: 15px;
    @include respond-below(xxl) {
      width: 50%;
    }

    @include respond-below(md) {
      width: 100%;
    }

    .block-sharethis {
      width: auto;
    }
  }

  .sharethis-wrapper {
    margin-top: 10px;
    justify-content: center;
    &::before {
      content: 'Share:';
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      color: #464646;
      margin: 0 3px 0 0;
    }
    span {
      width: 23px;
      height: 23px;

      &::before {
        font-size: 13px;
        line-height: 23px;
      }
    }
  }

  .group-units-row {
    display: flex;
    > div {
      margin-right: 14px;
    }
  }

  /*
    ======================================================
      Product Collection Table
    ======================================================
  */
  .product-collection-table {
    padding: 30px 0;
    overflow-x: auto;
    width: 100%;
    min-width: 300px;

    .uc-price {
      font-weight: 400;
    }

    .product-collection-table-row > form > div,
    .product-collection-table-header-row {
      display: flex;

      > div {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #1a1a1a;

        &.table-header-sku,
        &.product-collection-row-sku {
          width: 11%;

          @include respond-below(md) {
            display: none;
          }
        }

        &.table-header-description,
        &.product-collection-row-short-desc {
          width: 20%;

          @include respond-below(md) {
            width: 33%;
          }
        }

        &.table-header-price,
        &.product-collection-row-price {
          width: 12%;
          @include respond-below(md) {
            width: 17%;
          }
        }

        &.table-header-qty-breaks,
        &.product-collection-row-qty-breaks {
          width: 15%;

          @include respond-below(md) {
            display: none;
          }
        }

        &.form-type-checkbox,
        &.table-header-quick-order {
          width: 11%;

          @include respond-below(md) {
            display: none;
          }
        }

        &.form-item-qty,
        &.table-header-quantity {
          width: 12%;
          @include respond-below(md) {
            width: 20%;
          }
        }

        &.form-actions,
        &.table-header-order {
          width: 18%;
          @include respond-below(md) {
            width: 30%;
          }
        }
      }
    }

    .product-collection-table-header-row {
      > div {
        background: #474747;
        border: 1px solid #d4d4d4;
        box-sizing: border-box;
        line-height: 1.2;
        color: #ffffff;
        padding: 3px 5px;
      }
    }

    .product-collection-table-row {
      &:nth-child(even) > form > div > div {
        background: #f1f8ff;
      }

      > form {
        > div {
          > div {
            min-height: 78px;
            padding: 3px 5px;
            margin: 0;
            border: 1px solid #d4d4d4;
          }

          .product-collection-row-short-desc {
            button {
              background: none;
              border: none;
              height: 100%;
            }
          }

          .product-collection-row-qty-breaks {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 10px 24px;
            > p {
              font-weight: 500;
            }
          }
          .form-type-checkbox {
            font-size: 0;

            label.control-label {
              @include custom-checkbox('box');
              width: 24px;
              height: 24px;
              // .checkmark::before {
              //   content: "\f08a";
              // }

              // input:checked ~ .checkmark:before {
              //   content: "\f004";
              // }
            }
          }
          .form-item-qty {
            color: #1a1a1a;
            label.control-label {
              display: none;
            }
            input.form-control[type='number'] {
              width: 33px;
              height: 22px;
              padding: 0;
              text-align: center;
              -moz-appearance: textfield;
              background: #ffffff;
              border: 1px solid #dedede;
              box-sizing: border-box;
              border-radius: 0;

              @include respond-below(sm) {
                width: 20px;
              }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              margin: 0;
              -webkit-appearance: none;
            }

            .product-minus,
            .product-plus {
              border: 1px solid #dedede;
              box-sizing: border-box;
              border-radius: 0;
              background: #dedede;
              height: 22px;
            }
          }
          .form-actions {
            position: relative;
            button {
              font-size: 13px;
              font-weight: 700;
              padding: 0.4rem 2rem;
              .icon {
                display: none;
              }
            }
            .uc-ajax-cart-alt-status-messages {
              .alert {
                animation: 1s fadeOut;
                animation-delay: 1s;
                animation-fill-mode: forwards;
                position: absolute;
                bottom: 35px;
                left: 0;
                > strong {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    
  }

  /*
    ======================================================
      Product Collection Carousel
    ======================================================
  */
  .field-name-field-parts-breakdown,
  .field-name-field-related-products {
    .field-label {
      font-weight: 500;
      font-size: 18px;
      line-height: 132.3%;
      color: #3f3f3f;
      position: relative;
      margin: 40px 0;

      &::after {
        content: '';
        border-top: 3px solid #e5e5e5;
        position: absolute;
        top: 10px;
        left: 170px;
        right: 0;
      }
    }
  }

  /*
  ======================================================
    Group: Product Collection Tabs
  ======================================================
  */
  .group-collection-tabs {
    margin-top: 40px;
    width: 100%;
    display: inline-block;

    .horizontal-tabs-list {
      border-bottom: 1px solid $grey-1;
      margin: 10px 0;
      > li {
        border-bottom: 6px solid transparent;

        &.selected {
          border-bottom-color: $primary-color;
        }

        a strong {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #3f3f3f;
        }
      }

      @include respond-below(sm) {
        display: flex;
        flex-direction: column;

        > li {
          border: 1px solid #b0b0b0;
          &.selected {
            border-bottom: 1px solid #b0b0b0;
            background-color: #009cde;

            a strong {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
