.region-copyright {
  background-color: $grey-6;
  color: $white;
  text-align: center;
  padding: 20px;
  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
